<template>
    <div class="inner_pages client_admin_page" :style="`
        --var-portal-tab-background-color: ${clientPortal.portal_tab_bg_color != null ? clientPortal.portal_tab_bg_color : '#2f7fed'};
        --var-header-bg-color: ${clientPortal.header_bgcolor != null ? clientPortal.header_bgcolor : '#ffffff'};
        --var-header-text-color: ${clientPortal.header_textcolor != null ? clientPortal.header_textcolor : '#121525'};
        --var-header-dropdown-text-color: ${clientPortal.header_textcolor === '#FFFFFF' ? '#32373b' : (clientPortal.header_textcolor != null ? clientPortal.header_textcolor : '#121525')};
        --var-portal-tab-bg-color12: ${clientPortal.portal_tab_bg_color != null ? clientPortal.portal_tab_bg_color + 12 : '#2f7eed12'};
        --var-portal-tab-bg-color50: ${clientPortal.portal_tab_bg_color != null ? clientPortal.portal_tab_bg_color + 50 : '#2f7eed50'};
        --var-portal-tab-text-color: ${clientPortal.portal_tab_text_color != null ? clientPortal.portal_tab_text_color : '#FFF'};
        --var-calendar-event-color: ${clientPortal.calendar_event_color != null ? clientPortal.calendar_event_color : '#ffe8c1'};
        --var-calendar-text-color: ${clientPortal.calendar_text_color != null ? clientPortal.calendar_text_color : '#121525'};
        --var-calendar-event-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.event_color ? clientPortal.calendar_settings.event_color : '#ffe8c1'};
        --var-calendar-text-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.text_color ? clientPortal.calendar_settings.text_color : '#121525'};
        --var-calendar-morning-text-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.morning_text_color ? clientPortal.calendar_settings.morning_text_color : '#121525'};
        --var-calendar-morning-icon-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.morning_icon_color ? clientPortal.calendar_settings.morning_icon_color : '#ff7553'};
        --var-calendar-morning-icon-color-light: ${clientPortal.calendar_settings && clientPortal.calendar_settings.morning_icon_color ? clientPortal.calendar_settings.morning_icon_color+25 : '#ff755325'};
        --var-calendar-afternoon-text-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.afternoon_text_color ? clientPortal.calendar_settings.afternoon_text_color : '#121525'};
        --var-calendar-afternoon-icon-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.afternoon_icon_color ? clientPortal.calendar_settings.afternoon_icon_color : '#f2a31d'};
        --var-calendar-afternoon-icon-color-light: ${clientPortal.calendar_settings && clientPortal.calendar_settings.afternoon_icon_color ? clientPortal.calendar_settings.afternoon_icon_color+25 : '#f2a31d25'};
        --var-calendar-evening-text-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.evening_text_color ? clientPortal.calendar_settings.evening_text_color : '#121525'};
        --var-calendar-evening-icon-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.evening_icon_color ? clientPortal.calendar_settings.evening_icon_color : '#548fe3'};
        --var-calendar-evening-icon-color-light: ${clientPortal.calendar_settings && clientPortal.calendar_settings.evening_icon_color ? clientPortal.calendar_settings.evening_icon_color+25 : '#548fe325'};
        --var-calendar-event-tag-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.event_tag_color ? clientPortal.calendar_settings.event_tag_color : '#ecf4ff'};
        --var-calendar-event-tag-text-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.event_tag_text_color ? clientPortal.calendar_settings.event_tag_text_color : '#2f7eed'};
        --var-calendar-event-cta-color: ${clientPortal.calendar_settings && clientPortal.calendar_settings.event_cta_color ? clientPortal.calendar_settings.event_cta_color : '#2f7eed'};
        --var-chat-btn-color: ${clientPortal.chat_button_bg_color != null ? clientPortal.chat_button_bg_color : '#2f7eed'};
        --var-chat-bg-color20: ${clientPortal.chat_button_bg_color != null ? clientPortal.chat_button_bg_color + 20 : '#2f7eed20'};
        --var-chat-bg-color90: ${clientPortal.chat_button_bg_color != null ? clientPortal.chat_button_bg_color + 90 : '#2f7eed90'};
        --var-chat-bg-color0: ${clientPortal.chat_button_bg_color != null ? clientPortal.chat_button_bg_color + '00' : '#2f7eed00'};
        --var-announcement-text-color: ${clientPortal.announcement_text_color != null ? clientPortal.announcement_text_color : '#121525'};
        --var-announcement-text-color20: ${clientPortal.announcement_text_color != null ? clientPortal.announcement_text_color + 20 : '#12152520'};
    `">
        <div class="loader_area" v-show="clientPortalLoader"><quote-loader/></div>
        <div class="respective_content" v-show="!clientPortalLoader">
            <div class="section_header mb-3">
                <h2>Client Portal</h2>
                <div class="tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                    <label>Tutorials</label><img src="@/assets/images/play.svg">
                    <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                        <ul>
                            <li @click="tutorial = true;">How to use the client portal feature</li>
                        </ul>
                    </div>
                </div>
                <ul class="ml-auto settings">
                    <li class="mr-3">
                        <label for="preview_portal" class="switch_option capsule_btn">
                            <h5>Preview Portal</h5>
                            <input type="checkbox" id="preview_portal" v-model="previewPortal" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li class="mr-2">
                        <label for="preview_login" class="switch_option capsule_btn">
                            <h5>Preview Login</h5>
                            <input type="checkbox" id="preview_login" v-model="previewLogin" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li @click="portalSetting = true" class="pointer mr-1" v-if="user.has_portal">
                        <button type="button" class="pointer">
                            <i class="fas fa-cog"></i>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="client_card">
                <div class="card_header pointer" :style="`background: ${clientPortal.header_bgcolor}; color: ${clientPortal.header_textcolor};`">
                    <nav>
                        <img v-if="clientPortal.logo && clientPortal.logo != null" class="logo_img" :src="clientPortal.logo" alt="logo">
                        <div class="mobile_bar" @click="navToggle($event)">
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5415 3.58333C12.5415 1.60431 14.1458 0 16.1248 0C18.1039 0 19.7082 1.60431 19.7082 3.58333C19.7082 5.56235 18.1039 7.16667 16.1248 7.16667C14.1458 7.16667 12.5415 5.56235 12.5415 3.58333Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5415 16.125C12.5415 14.1459 14.1458 12.5416 16.1248 12.5416C18.1039 12.5416 19.7082 14.1459 19.7082 16.125C19.7082 18.104 18.1039 19.7083 16.1248 19.7083C14.1458 19.7083 12.5415 18.104 12.5415 16.125Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5415 28.6667C12.5415 26.6876 14.1458 25.0834 16.1248 25.0834C18.1039 25.0834 19.7082 26.6876 19.7082 28.6667C19.7082 30.6458 18.1039 32.25 16.1248 32.25C14.1458 32.25 12.5415 30.6458 12.5415 28.6667Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0835 3.58333C25.0835 1.60431 26.6878 0 28.6668 0C30.6459 0 32.2502 1.60431 32.2502 3.58333C32.2502 5.56235 30.6459 7.16667 28.6668 7.16667C26.6878 7.16667 25.0835 5.56235 25.0835 3.58333Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0835 16.125C25.0835 14.1459 26.6878 12.5416 28.6668 12.5416C30.6459 12.5416 32.2502 14.1459 32.2502 16.125C32.2502 18.104 30.6459 19.7083 28.6668 19.7083C26.6878 19.7083 25.0835 18.104 25.0835 16.125Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0835 28.6667C25.0835 26.6876 26.6878 25.0834 28.6668 25.0834C30.6459 25.0834 32.2502 26.6876 32.2502 28.6667C32.2502 30.6458 30.6459 32.25 28.6668 32.25C26.6878 32.25 25.0835 30.6458 25.0835 28.6667Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.58333C0 1.60431 1.60431 0 3.58333 0C5.56235 0 7.16667 1.60431 7.16667 3.58333C7.16667 5.56235 5.56235 7.16667 3.58333 7.16667C1.60431 7.16667 0 5.56235 0 3.58333Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.125C0 14.1459 1.60431 12.5416 3.58333 12.5416C5.56235 12.5416 7.16667 14.1459 7.16667 16.125C7.16667 18.104 5.56235 19.7083 3.58333 19.7083C1.60431 19.7083 0 18.104 0 16.125Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 28.6667C0 26.6876 1.60431 25.0834 3.58333 25.0834C5.56235 25.0834 7.16667 26.6876 7.16667 28.6667C7.16667 30.6458 5.56235 32.25 3.58333 32.25C1.60431 32.25 0 30.6458 0 28.6667Z" fill="#999999"/>
                            </svg>
                        </div>
                        <div class="nav_wrapper">
                            <span @click="navToggle($event)" class="close"><img src="@/assets/images/close.svg"></span>
                            <portal-navigation v-model:tab="navigationTab" v-model:subtab="classroomTab" :settings="clientPortal"/>
                        </div>
                    </nav>
                    <div class="user_box">
                        <img :src="clientPortal.author ? clientPortal.author : user.profile_pic" :alt="user.full_name">
                        <div class="user_info">
                            <h5 :style="`color: ${clientPortal.header_textcolor};`">{{ user.full_name }}</h5>
                        </div>
                    </div>
                    <button class="header_setting" v-if="user.has_portal" @click="headerSetting = true"><i class="fas fa-cog"></i></button>
                </div>
                <div class="card_body">
                    <div @click="user.has_portal ? coverSetting = true : ''" class="pointer w-100" :class="{ 'draft': !form.display_cover , 'cover-image' : form.cover_type == 1 }">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-cover.svg')" alt="banner" class="banner_img" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-sm-cover.svg')" alt="banner" class="banner_img tab" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="banner" class="banner_img cell" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                        <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <div class="banner_content desk">
                            <h4 class="shadow-none" v-if="form.is_headline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.headline_setting.font_size}px; line-height: ${parseInt(form.headline_setting.font_size) + 20}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.is_subheadline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.subheadline_setting.font_size}px; line-height: ${parseInt(form.subheadline_setting.font_size) + 20}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                        </div>
                        <div class="banner_content tab">
                            <h4 class="shadow-none" v-if="form.is_headline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.headline_setting.font_size / 2}px; line-height: ${(parseInt(form.headline_setting.font_size) + 20) / 2}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.is_subheadline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.subheadline_setting.font_size / 1.5}px; line-height: ${(parseInt(form.subheadline_setting.font_size) + 20) / 2}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                        </div>
                        <div class="banner_content cell">
                            <h4 class="shadow-none" v-if="form.is_headline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.headline_setting.font_size / 3}px; line-height: ${(parseInt(form.headline_setting.font_size) + 20) / 3}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.is_subheadline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.subheadline_setting.font_size / 2}px; line-height: ${(parseInt(form.subheadline_setting.font_size) + 20) / 2}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                        </div>
                    </div>
                    <image-library v-if="user.has_portal" v-model="coverImage" :is-button="true" image-type="public-cover" upload-text="Cover" />
                    <div class="action" v-if="user.has_portal">
                        <label for="active_banner" class="switch_option capsule_btn">
                            <h5 class="mr-2">{{ form.display_cover ? 'Active' : 'Inactive' }}</h5>
                            <input type="checkbox" id="active_banner" :true-value="1" :false-value="0" v-model="form.display_cover" @change="updatePortal({ display_cover: form.display_cover, id: form.id })" hidden>
                            <div><span></span></div>
                        </label>
                        <i class="fas fa-cog" @click="coverSetting = true"></i>
                    </div>
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab === 'home'">
                <div class="board_status">
                    <draggable v-model="draggableWidgets" item-key="id" tag="ul" :animation="200" handle=".handle-cat-drag" @change="handleSortWidgets">
                        <template #item="{ element }">
                            <li>
                                <template v-if="element === 'welcome'">
                                    <h3>
                                        <i class="fas fa-arrows-alt mr-2 handle-cat-drag"></i>
                                        <label for="welcome-widget" class="switch_option capsule_btn border-0 p-0">
                                            <input type="checkbox" id="welcome-widget" :true-value="1" :false-value="0" v-model="form.has_welcome_video" @change="updatePortal({ has_welcome_video: form.has_welcome_video, id: form.id })" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <button type="button" class="edit" @click="welcomeToggle = true; welcomeToggleType = 1;"><i class="fas fa-cog"></i></button>
                                    </h3>
                                    <client-board type="welcome"/>
                                </template>
                                <template v-if="element === 'announcement'">
                                    <h3>
                                        <i class="fas fa-arrows-alt mr-2 handle-cat-drag"></i>
                                        <label for="announcement-widget" class="switch_option capsule_btn border-0 p-0">
                                            <input type="checkbox" id="announcement-widget" :true-value="1" :false-value="0" v-model="form.has_announcement" @change="updatePortal({ has_announcement: form.has_announcement, id: form.id })" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <button type="button" class="edit" @click="announcementToggle = true; announcementToggleType = 2;"><i class="fas fa-cog"></i></button>
                                    </h3>
                                    <client-board type="announcement"/>
                                </template>
                                <template v-if="element === 'today_tasks'">
                                    <h3>
                                        <i class="fas fa-arrows-alt mr-2 handle-cat-drag"></i>
                                        <label for="task-widget" class="switch_option capsule_btn border-0 p-0">
                                            <input type="checkbox" id="task-widget" :true-value="1" :false-value="0" v-model="form.has_today_tasks" @change="updatePortal({ has_today_tasks: form.has_today_tasks, id: form.id })" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <button type="button" class="edit" @click="taskToggle = true; taskToggleType = 3;"><i class="fas fa-cog"></i></button>
                                    </h3>
                                    <client-board type="tasks" v-model:tab="navigationTab"/>
                                </template>
                            </li>
                        </template>
                    </draggable>
                </div>
                <div class="full_width p-0">
                    <div class="section_item">
                        <h3 class="mb-1">
                            <div class="title" :style="`color: ${clientPortal.announcement_title_text_color}`">Pinned Training <span v-tooltip="`To add pinned training, go to your playbooks dashboard, hover over a playbook, toggle the pin icon on.`"><i class="tooltip_icon fas fa-info"></i></span></div>
                            <label for="pinned-widget" class="switch_option capsule_btn border-0 p-0">
                                <input type="checkbox" id="pinned-widget" :true-value="1" :false-value="0" v-model="form.pinned_widget" @change="updatePortal({ pinned_widget: form.pinned_widget, id: form.id })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="sortPlaybook = true;"><i class="fas fa-cog"></i></button>
                        </h3>
                        <div class="sliderWpr" v-if="form.pinned_widget">
                            <ul class="client_productList">
                                <li v-for="(playbook, p) of pinnedPlaybooks" :key="p">
                                    <div class="product_card">
                                        <img v-if="playbook.dashboard_thumb" :src="playbook.dashboard_thumb" alt="" class="product_thumb">
                                        <img v-else src="@/assets/images/thumb/default-playbook.svg" alt="" class="product_thumb">
                                        <div class="product_cont playbook-desc">
                                            <h4>{{ playbook.title.length > 35 ? playbook.title.substr(0, 35)+'...' : playbook.title }}</h4>
                                            <p>{{ playbook.description && playbook.description.length > 70 ? playbook.description.substr(0, 70)+'...' : playbook.description }}</p>
                                            <a href="javascript:void(0);" class="primary_btn custom_element">Open Playbook</a>
                                        </div>
                                        <button type="button" class="pin custom-pin"><i class="fas fa-thumbtack"></i></button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab !== 'home' && navigationTab !== 'calendar' && navigationTab !== 'tracking'">
                <div class="full_width">
                    <div class="portal_forms">
                        <div class="result_wpr new" v-if="classroomTab === 'playbooks'">
                            <div class="actions mt-2 mb-2">
                                <h3 class="section_title">
                                    {{playbookCategoryFilterText !== 'Filter' ? playbookCategoryFilterText : `My ${clientPortal.playbook_tab_name}` }}
                                    <div class="categories_list" @click="filterList = !filterList" v-click-outside="closeFilters" v-if="classroomTab === 'playbooks'">
                                        {{ playbookCategoryFilterText }} <i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                                            <ul>
                                                <li @click="handleCategoryFilter({ id: 0, name: 'All' })">All</li>
                                                <li v-for="(category, c) of playbookCategories" :key="c" @click="handleCategoryFilter(category)">{{ category.name }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </h3>
                                <ul>
                                    <li class="optionDrops search_area" :class="{'active': searchField}">
                                        <input type="text" placeholder="Search" :disabled="assetsLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                        <button class="search_btn" @click="searchField = !searchField;">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </li>
                                    <!-- <li class="optionDrops contacts-tabs" @click="filterList = !filterList" v-click-outside="closeFilters" v-if="classroomTab === 'playbooks'">
                                        {{ playbookCategoryFilterText }} <i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                                            <ul>
                                                <li @click="handleCategoryFilter({ id: 0, name: 'All' })">All</li>
                                                <li v-for="(category, c) of playbookCategories" :key="c" @click="handleCategoryFilter(category)">{{ category.name }}</li>
                                            </ul>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <playbook-component :title="clientPortal.playbook_tab_name" :search="params.search" :category="selectedPlaybookCategory" v-if="classroomTab === 'playbooks'" />
                        <page-component :title="clientPortal.page_tab_name" :search="params.search" v-if="classroomTab === 'pages'" />
                        <form-component :title="clientPortal.form_tab_name" :search="params.search" v-if="classroomTab === 'forms'" />
                        <workout-component :title="clientPortal.workout_tab_name" :search="params.search" v-if="classroomTab === 'workout-library'" />
                        <workout-plan-component :title="clientPortal.plans_tab_name" :search="params.search" v-if="classroomTab === 'workout-programs'" />
                        <file-component :title="clientPortal.file_tab_name" :search="params.search" v-if="classroomTab === 'files'" />
                    </div>
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab == 'calendar'">
                <div class="full_width event_calendar">
                    <full-calendar class="fullCalendar" ref="portalCalendar" :options='calendarOptions' v-show="showCalendar"></full-calendar>
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab == 'tracking'">
                <div class="full_width">
                    <charts-component :client-portal="clientPortal" />
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab == 'onboarding'">
                <div class="full_width">
                    <h1 class="text-center">Coming Soon!</h1>
                </div>
            </div>
            <footer class="footer-content">
                <div class="step_action" v-if="user.has_portal">
                    <label for="footercard" class="switch_option capsule_btn border-0 p-0 ml-auto">
                        <input type="checkbox" id="footercard" :true-value="1" :false-value="0" v-model="form.display_footer" @change="updatePortal({ display_footer: form.display_footer, id: form.id })" hidden>
                        <div><span></span></div>
                    </label>
                    <button type="button" class="edit" @click="footerSetting = true"><i class="fas fa-cog"></i></button>
                </div>
                <div class="dashboard_footer" :class="{'draft' : !form.display_footer}" :style="`background: ${clientPortal.footer_bgcolor}`">
                    <ul>
                        <li class="pointer" v-if="clientPortal.has_facebook"><a :href="clientPortal.footer_facebook" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="pointer" v-if="clientPortal.has_twitter"><a :href="clientPortal.footer_twitter" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                        <li class="pointer" v-if="clientPortal.has_instagram"><a :href="clientPortal.footer_instagram" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                        <li class="pointer" v-if="clientPortal.has_linkedin"><a :href="clientPortal.footer_linkedin" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor}`"><i class="fab fa-linkedin"></i></a></li>
                        <li class="pointer" v-if="clientPortal.has_youtube"><a :href="clientPortal.footer_youtube" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor}`"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                    <h4 :style="`color: ${clientPortal.footer_textcolor};`">{{ form.footer_company }}</h4>
                    <p>
                        <a :href="clientPortal.footer_terms" :style="`color: ${clientPortal.footer_textcolor};`">Terms</a>
                        <a :href="clientPortal.footer_policy" :style="`color: ${clientPortal.footer_textcolor};`">Privacy</a>
                        <span v-html="form.white_label"></span>
                    </p>
                </div>
            </footer>
            <button type="button" v-if="clientPortal.has_chatbox" class="chat_btn pointer ripple" :class="clientPortal.chat_placement" @click="chat = true" :style="`background: ${clientPortal.chat_button_bg_color}; ${clientPortal.chat_placement}: ${ clientPortal.chat_placement == 'left' ? 50 : 50 }px;`">
                <template v-if="has_new_msg">
                    <img v-if="clientPortal.chat_has_coach_custom_image && clientPortal.chat_coach_image" :src="clientPortal.chat_coach_image" alt="">
                    <div v-else class="coach_name">{{ user && user.fname ? user.fname[0] : '' }}{{ user && user.fname ? user.lname[0] : '' }}</div>
                    <label><i class="fas fa-comments mr-2"></i>CHAT</label>
                </template>
                <template v-else>
                    <i class="fas fa-comments"></i>
                    <label class="ml-2">CHAT</label>
                </template>
            </button>
            <div class="chat_bg" :class="{'expand' : chat_expand}" v-if="chat">
                <div class="chatbox_wpr" :class="clientPortal.chat_placement" :style="`background: ${clientPortal.chat_background_color};`">
                    <button type="button" class="expand_toggle" @click="chat_expand = !chat_expand;"><i :class="chat_expand ? 'fas fa-compress' : 'fas fa-expand'"></i></button>
                    <button type="button" class="close_btn" @click="chat = false"><i class="fas fa-times"></i></button>
                    <div class="chat_header">
                        <h2>Hi, Jason</h2>
                        <h4>Need help? Drop us a message and we'll get back to you soon.</h4>
                        <div class="coach">
                            <div class="coach_box">
                                <img v-if="clientPortal.chat_has_coach_custom_image && clientPortal.chat_coach_image" :src="clientPortal.chat_coach_image" alt="">
                                <div v-else class="coach_name">
                                    <template v-if="user.fname">{{ user.fname[0] }}</template>
                                    <template v-if="user.lname">{{ user.lname[0] }}</template>
                                </div>
                            </div>
                            <h5>{{user.fname}} {{ user.lname }}<span>{{user.businessname}}</span></h5>
                        </div>
                    </div>
                    <chat :setting="clientPortal" :color="clientPortal.chat_button_bg_color"/>
                </div>
            </div>
        </div>
        <setting-component v-model="portalSetting" />
        <header-setting v-model="headerSetting" />
        <cover-setting v-model="coverSetting" />
        <footer-setting v-model="footerSetting" />
        <login-preview v-model="previewLogin" />
        <portal-preview v-model="previewPortal" />
        <welcome-setting v-model="welcomeToggle" :type="welcomeToggleType" />
        <announcement-settings v-model="announcementToggle" :type="announcementToggleType" />
        <task-settings v-model="taskToggle" :type="taskToggleType" />
        <div class="modal video_player" v-if="showWelcomeVideo" :class="{ active : showWelcomeVideo }" @click.self="showWelcomeVideo = false">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="showWelcomeVideo = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body p-0" style="margin-right: -4px;">
                    <div class="video_wpr" v-html="clientPortal.welcome_video_source"></div>
                </div>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/0317ac8d46164d4c8910132f51f2b845?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
        <div class="modal workout_modal" v-if="showEventDrawer" :class="{active : showEventDrawer}">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Activity <span>{{ moment(selectedEventDate).isSame(moment(), 'day') ? 'Today' : selectedEventDate }}</span></h1>
                        <ul class="btn_list">
                            <li v-if="toggleAll" @click="openAllSection()">Open All</li>
                            <li v-else @click="closeAllSection()">Close All</li>
                        </ul>
                    </div>
                    <button class="close_btn left_out" @click="showEventDrawer = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="slot_content">
                            <div class="slot_label morning" @click="toggleList($event)">
                                <svg class="morning" width="150" height="76" viewBox="0 0 150 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M46.2473 44.5521C60.1386 26.6813 85.3425 27.4029 99.4461 41.2411C105.594 47.2729 109.223 55.3284 110.025 63.7045C110.088 64.3579 109.492 64.8725 108.848 64.7481C78.7707 58.9419 51.5654 62.091 40.322 64.6799C39.6618 64.8319 39.0417 64.3087 39.1041 63.6341C39.7566 56.5804 42.1886 49.7686 46.2473 44.5521Z" fill="#e9e9e9"/>
                                    <path d="M0 76C23.3193 69.906 84.5238 61.5505 150 76H0Z" fill="#e9e9e9"/>
                                    <path d="M92.8793 25.942C91.2045 27.1984 90.9017 28.6262 92.4836 29.8791C94.0656 31.132 104.297 38.0927 105.203 38.5854C106.519 39.3043 107.494 38.518 107.883 37.2588C108.261 35.9951 113.286 18.1632 113.744 16.527C114.203 14.8907 113.305 12.6221 110.83 13.9384C108.354 15.2547 94.1401 24.996 92.8793 25.942Z" fill="#e9e9e9"/>
                                    <path d="M112.462 45.5531C110.451 45.5558 110.155 46.6971 110.699 48.4301C111.242 50.1631 114.834 62.5843 115.231 63.495C115.641 64.3985 116.65 64.9091 117.874 64.1088C119.094 63.3188 135.04 51.1292 136.151 50.4054C137.984 49.2153 137.704 47.2748 135.493 47.1713C133.803 47.1061 113.727 45.5542 112.462 45.5531Z" fill="#e9e9e9"/>
                                    <path d="M64.7444 22.2849C64.0584 24.2631 64.6058 25.6162 66.618 25.7702C68.6301 25.9243 81.0047 25.9735 82.0314 25.8754C83.5242 25.7357 83.8933 24.5383 83.5109 23.2773C83.1181 22.0179 77.3124 4.4248 76.7776 2.81188C76.2428 1.19896 74.2301 -0.179877 72.9138 2.29568C71.5975 4.77123 65.2606 20.7956 64.7444 22.2849Z" fill="#e9e9e9"/>
                                    <path d="M30.3418 63.4812C32.6732 64.6609 33.8159 64.3358 34.3998 62.6544C35.1693 60.4511 37.6345 50.3001 37.847 47.9731C37.9868 46.4607 37.4349 44.9666 35.0846 45.2204C32.7342 45.4742 15.8492 46.974 13.671 47.6083C11.1366 48.3523 11.1793 51.0958 12.7891 52.048C14.6982 53.1746 28.6938 62.6546 30.3418 63.4812Z" fill="#e9e9e9"/>
                                    <path d="M56.368 28.3441C57.6172 27.1589 57.0708 25.662 55.7926 24.9323C54.5143 24.2026 38.6427 14.7148 36.6208 13.4003C34.8367 12.2461 32.8265 12.8907 33.6467 15.8123C34.2352 17.9127 40.6278 36.1874 41.0684 37.4484C41.5688 38.9067 42.7541 39.5487 43.9731 38.5046C45.1718 37.4729 55.0706 29.5868 56.368 28.3441Z" fill="#e9e9e9"/>
                                </svg>
                                <h2>{{ clientPortal.calendar_settings.morning_text }}</h2>
                                <i class="fas fa-chevron-down"></i>
                            </div>
                            <div class="toggle_content">
                                <h3 class="sub_heading2">Workouts <i class="fas fa-caret-down"></i></h3>
                                <ul class="workout_card workout_list show">
                                    <li>
                                        <div class="card_wrapper">
                                            <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M17.8241 12.7621C12.3284 15.7511 11.2758 21.3192 11.4365 23.7296C11.7981 27.9478 15.5342 31.0814 16.9805 32.1661C18.1375 33.0338 19.7123 32.5276 20.3551 32.1661C26.8632 28.671 31.5636 27.4657 41.0848 27.3452C50.6059 27.2247 57.9578 31.684 59.5245 32.4071C60.778 32.9856 61.7341 32.6482 62.0555 32.4071C66.4907 29.4182 67.8405 25.3767 67.961 23.7296C68.5636 15.6547 60.7297 12.4006 60.0066 12.1595C59.4281 11.9667 57.8372 11.8381 57.1141 11.798C47.7134 11.1954 22.886 11.798 21.0782 11.798C19.632 11.798 18.3062 12.4408 17.8241 12.7621Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M32.1058 47.5454V72.9999H28.2528V51.3984H28.1036L22.0134 55.3757V51.6967L28.3646 47.5454H32.1058Z" fill="#858585"/>
                                                <path d="M47.8533 73.4225C45.8895 73.4142 44.2116 72.8964 42.8195 71.8689C41.4275 70.8414 40.3627 69.3458 39.6253 67.382C38.8878 65.4183 38.5191 63.0526 38.5191 60.2851C38.5191 57.5259 38.8878 55.1685 39.6253 53.213C40.371 51.2575 41.4399 49.766 42.832 48.7386C44.2323 47.7111 45.9061 47.1974 47.8533 47.1974C49.8005 47.1974 51.4701 47.7153 52.8622 48.751C54.2542 49.7785 55.3189 51.2699 56.0564 53.2254C56.8021 55.1726 57.175 57.5259 57.175 60.2851C57.175 63.0609 56.8063 65.4307 56.0688 67.3945C55.3314 69.35 54.2666 70.8456 52.8746 71.8813C51.4825 72.9088 49.8088 73.4225 47.8533 73.4225ZM47.8533 70.104C49.5768 70.104 50.9232 69.263 51.8927 67.5809C52.8704 65.8989 53.3593 63.4669 53.3593 60.2851C53.3593 58.1722 53.1356 56.3865 52.6881 54.9282C52.249 53.4616 51.6151 52.3513 50.7865 51.5972C49.9662 50.8349 48.9885 50.4538 47.8533 50.4538C46.1381 50.4538 44.7916 51.2989 43.8139 52.9893C42.8361 54.6796 42.3431 57.1116 42.3348 60.2851C42.3348 62.4063 42.5544 64.2002 42.9935 65.6668C43.441 67.1252 44.0749 68.2314 44.8952 68.9854C45.7155 69.7311 46.7015 70.104 47.8533 70.104Z" fill="#858585"/>
                                            </svg>
                                            <div class="info_wpr">
                                                <h4 @click="toggleDetails($event)">Week 1 Foundation - Legs and Core<span class="tag">Scheduled: 6:00 AM</span><i class="fas fa-chevron-down"></i></h4>
                                                <div class="details">
                                                    <div class="blocks">
                                                        <div class="block_title">Warm-up<span class="block_type">Performed as a Circuit</span>
                                                            <h6>
                                                                <span>1 round</span>
                                                                <span>1 min work</span>
                                                                <span>20 sec rest</span>
                                                            </h6>
                                                        </div>
                                                        <div class="block_item border-0">
                                                            <div class="table_wpr">
                                                                <table class="task_history">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Work Time</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Squat <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Maximus/Squat.gif" alt="Squat">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>01 : 00</td>
                                                                            <td>Bodyweight</td>
                                                                            <td>00 : 20</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Resistance Band Lateral Walk <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Medius/Resistance-Band-Lateral-Walk.gif" alt="Resistance Band Lateral Walk">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>01 : 00</td>
                                                                            <td>Bodyweight</td>
                                                                            <td>00 : 20</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Medicine Ball Lunge with Biceps Curl <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Quadriceps-Group/Quadriceps/Medicine-Ball-Lunge-with-Biceps-Curl.gif" alt="Medicine Ball Lunge with Biceps Curl">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>01 : 00</td>
                                                                            <td>10 lbs</td>
                                                                            <td>00 : 20</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="blocks">
                                                        <div class="block_title">Legs and Core<span class="block_type">Performed as a Progression</span>
                                                        </div>
                                                        <div class="block_item border-0">
                                                            <div class="table_wpr">
                                                                <table class="task_history">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Sets</th>
                                                                            <th>Reps</th>
                                                                            <th>Tempo</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colspan="5" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Kettlebell Goblet Squat <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Maximus/Kettlebell-Goblet-Squat.gif" alt="Kettlebell Goblet Squat">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>3</td>
                                                                            <td>12</td>
                                                                            <td>3131</td>
                                                                            <td>60% 1rm</td>
                                                                            <td>00 : 30</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Dumbbell Split Squat <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Quadriceps-Group/Quadriceps/Dumbbell-Split-Squat.gif" alt="Dumbbell Split Squat">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>3</td>
                                                                            <td>12</td>
                                                                            <td>3131</td>
                                                                            <td>60% 1rm</td>
                                                                            <td>00 : 30</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Step up <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Quadriceps-Group/Quadriceps/Step-up.gif" alt="Step up">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>3</td>
                                                                            <td>12</td>
                                                                            <td>3131</td>
                                                                            <td>Bodyweight</td>
                                                                            <td>00 : 30</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Twisting Crunch (Straight Arms) <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Abs---Core/Rectus-Abdominus---Completed/Twisting-Crunch--Straight-Arms-.gif" alt="Twisting Crunch (Straight Arms)">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>3</td>
                                                                            <td>12</td>
                                                                            <td>3131</td>
                                                                            <td>Bodyweight</td>
                                                                            <td>00 : 30</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Spell Caster <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Abs---Core/Rectus-Abdominus---Completed/Spell-Caster.gif" alt="Spell Caster">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>3</td>
                                                                            <td>12</td>
                                                                            <td>3131</td>
                                                                            <td>60% 1rm</td>
                                                                            <td>00 : 30</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="feedback_btn" @click="feedbackToggle($event)">Show Feedback</div>
                                                    <div class="workout_reviews">
                                                        <div class="finish_timer">
                                                            <h3>Finished Time</h3>
                                                            <div class="total_timer">
                                                                <label>00<span>hrs</span></label>:<label>27<span>min</span></label>:<label>00<span>sec</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="reviews">
                                                            <h3>Overall Ratings</h3>
                                                            <ul>
                                                                <li><span>4</span>Difficulty</li>
                                                                <li><span>3</span>Enjoyment</li>
                                                                <li><span>4</span>Energy</li>
                                                                <li><span>3</span>Mood</li>
                                                            </ul>
                                                        </div>
                                                        <div class="notes">
                                                            <h4>Notes:</h4>
                                                            <p>Overall, a successful workout today. I'm excited to see where my strength training journey takes me from here.</p>
                                                        </div>
                                                    </div>
                                                    <div class="footer_action">
                                                        <span class="cloud-icon">Edited After Submission</span>
                                                        <p class="already-submitted">Already submitted Sept 9, 2023 at 6:00 AM America/New_York</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="card_wrapper">
                                            <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M17.8241 12.7621C12.3284 15.7511 11.2758 21.3192 11.4365 23.7296C11.7981 27.9478 15.5342 31.0814 16.9805 32.1661C18.1375 33.0338 19.7123 32.5276 20.3551 32.1661C26.8632 28.671 31.5636 27.4657 41.0848 27.3452C50.6059 27.2247 57.9578 31.684 59.5245 32.4071C60.778 32.9856 61.7341 32.6482 62.0555 32.4071C66.4907 29.4182 67.8405 25.3767 67.961 23.7296C68.5636 15.6547 60.7297 12.4006 60.0066 12.1595C59.4281 11.9667 57.8372 11.8381 57.1141 11.798C47.7134 11.1954 22.886 11.798 21.0782 11.798C19.632 11.798 18.3062 12.4408 17.8241 12.7621Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M32.1058 47.5454V72.9999H28.2528V51.3984H28.1036L22.0134 55.3757V51.6967L28.3646 47.5454H32.1058Z" fill="#858585"/>
                                                <path d="M47.8533 73.4225C45.8895 73.4142 44.2116 72.8964 42.8195 71.8689C41.4275 70.8414 40.3627 69.3458 39.6253 67.382C38.8878 65.4183 38.5191 63.0526 38.5191 60.2851C38.5191 57.5259 38.8878 55.1685 39.6253 53.213C40.371 51.2575 41.4399 49.766 42.832 48.7386C44.2323 47.7111 45.9061 47.1974 47.8533 47.1974C49.8005 47.1974 51.4701 47.7153 52.8622 48.751C54.2542 49.7785 55.3189 51.2699 56.0564 53.2254C56.8021 55.1726 57.175 57.5259 57.175 60.2851C57.175 63.0609 56.8063 65.4307 56.0688 67.3945C55.3314 69.35 54.2666 70.8456 52.8746 71.8813C51.4825 72.9088 49.8088 73.4225 47.8533 73.4225ZM47.8533 70.104C49.5768 70.104 50.9232 69.263 51.8927 67.5809C52.8704 65.8989 53.3593 63.4669 53.3593 60.2851C53.3593 58.1722 53.1356 56.3865 52.6881 54.9282C52.249 53.4616 51.6151 52.3513 50.7865 51.5972C49.9662 50.8349 48.9885 50.4538 47.8533 50.4538C46.1381 50.4538 44.7916 51.2989 43.8139 52.9893C42.8361 54.6796 42.3431 57.1116 42.3348 60.2851C42.3348 62.4063 42.5544 64.2002 42.9935 65.6668C43.441 67.1252 44.0749 68.2314 44.8952 68.9854C45.7155 69.7311 46.7015 70.104 47.8533 70.104Z" fill="#858585"/>
                                            </svg>
                                            <div class="info_wpr">
                                                <h4 @click="toggleDetails($event)">Week 1 Foundation - Core and Cardio<span class="tag">Scheduled: 7:00 AM</span><i class="fas fa-chevron-down"></i></h4>
                                                <div class="details">
                                                    <div class="blocks">
                                                        <div class="block_title">Warm-up<span class="block_type">Performed as a Circuit</span>
                                                            <h6>
                                                                <span>3 rounds</span>
                                                                <span>30 sec work</span>
                                                                <span>20 sec rest</span>
                                                            </h6>
                                                        </div>
                                                        <div class="block_item border-0">
                                                            <div class="table_wpr">
                                                                <table class="task_history">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Work Time</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Skater Hops <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Cardio/Skater-Hops.gif" alt="Skater Hops">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>00 : 30</td>
                                                                            <td>Bodyweight</td>
                                                                            <td>00 : 20</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Side to Side Jump Rope (male) Cardio <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Cardio/Side-to-Side-Jump-Rope--male-_Cardio_.gif" alt="Side to Side Jump Rope (male) Cardio">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>00 : 30</td>
                                                                            <td>Bodyweight</td>
                                                                            <td>00 : 20</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Elbow to Knee Sit up <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Abs---Core/Obliques---Completed/Elbow-to-Knee-Sit-up.gif" alt="Elbow to Knee Sit up">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>00 : 30</td>
                                                                            <td>Bodyweight</td>
                                                                            <td>00 : 20</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3" class="exercise_title" @click="toggleExercise($event)">
                                                                                <div class="title">Weighted Stretch Lunge Thighs <i class="far fa-eye"></i></div>
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Hamstring/Hamstrings/Weighted-Stretch-Lunge_Thighs.gif" alt="Weighted Stretch Lunge Thighs">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>00 : 30</td>
                                                                            <td>135 lbs</td>
                                                                            <td>00 : 20</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="feedback_btn" @click="feedbackToggle($event)">Show Feedback</div>
                                                    <div class="workout_reviews">
                                                        <div class="finish_timer">
                                                            <h3>Finished Time</h3>
                                                            <div class="total_timer">
                                                                <label>00<span>hrs</span></label>:<label>10<span>min</span></label>:<label>43<span>sec</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="reviews">
                                                            <h3>Overall Ratings</h3>
                                                            <ul>
                                                                <li><span>4</span>Difficulty</li>
                                                                <li><span>3</span>Enjoyment</li>
                                                                <li><span>4</span>Energy</li>
                                                                <li><span>3</span>Mood</li>
                                                            </ul>
                                                        </div>
                                                        <div class="notes">
                                                            <h4>Notes:</h4>
                                                            <p>Overall, a successful workout today. I'm excited to see where my strength training journey takes me from here.</p>
                                                        </div>
                                                    </div>
                                                    <div class="footer_action">
                                                        <span class="cloud-icon green">Submitted On Time</span>
                                                        <p class="already-submitted">Already submitted Sept 8, 2023 at 7:00 AM America/New_York</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="card_wrapper">
                                            <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M17.8241 12.7621C12.3284 15.7511 11.2758 21.3192 11.4365 23.7296C11.7981 27.9478 15.5342 31.0814 16.9805 32.1661C18.1375 33.0338 19.7123 32.5276 20.3551 32.1661C26.8632 28.671 31.5636 27.4657 41.0848 27.3452C50.6059 27.2247 57.9578 31.684 59.5245 32.4071C60.778 32.9856 61.7341 32.6482 62.0555 32.4071C66.4907 29.4182 67.8405 25.3767 67.961 23.7296C68.5636 15.6547 60.7297 12.4006 60.0066 12.1595C59.4281 11.9667 57.8372 11.8381 57.1141 11.798C47.7134 11.1954 22.886 11.798 21.0782 11.798C19.632 11.798 18.3062 12.4408 17.8241 12.7621Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M32.1058 47.5454V72.9999H28.2528V51.3984H28.1036L22.0134 55.3757V51.6967L28.3646 47.5454H32.1058Z" fill="#858585"/>
                                                <path d="M47.8533 73.4225C45.8895 73.4142 44.2116 72.8964 42.8195 71.8689C41.4275 70.8414 40.3627 69.3458 39.6253 67.382C38.8878 65.4183 38.5191 63.0526 38.5191 60.2851C38.5191 57.5259 38.8878 55.1685 39.6253 53.213C40.371 51.2575 41.4399 49.766 42.832 48.7386C44.2323 47.7111 45.9061 47.1974 47.8533 47.1974C49.8005 47.1974 51.4701 47.7153 52.8622 48.751C54.2542 49.7785 55.3189 51.2699 56.0564 53.2254C56.8021 55.1726 57.175 57.5259 57.175 60.2851C57.175 63.0609 56.8063 65.4307 56.0688 67.3945C55.3314 69.35 54.2666 70.8456 52.8746 71.8813C51.4825 72.9088 49.8088 73.4225 47.8533 73.4225ZM47.8533 70.104C49.5768 70.104 50.9232 69.263 51.8927 67.5809C52.8704 65.8989 53.3593 63.4669 53.3593 60.2851C53.3593 58.1722 53.1356 56.3865 52.6881 54.9282C52.249 53.4616 51.6151 52.3513 50.7865 51.5972C49.9662 50.8349 48.9885 50.4538 47.8533 50.4538C46.1381 50.4538 44.7916 51.2989 43.8139 52.9893C42.8361 54.6796 42.3431 57.1116 42.3348 60.2851C42.3348 62.4063 42.5544 64.2002 42.9935 65.6668C43.441 67.1252 44.0749 68.2314 44.8952 68.9854C45.7155 69.7311 46.7015 70.104 47.8533 70.104Z" fill="#858585"/>
                                            </svg>
                                            <div class="info_wpr">
                                                <h4 @click="toggleDetails($event)">Week 1 Foundation - Core and Cardio
                                                    <div class="lim_width">
                                                        <span class="tag">Schedule: 8:00 AM</span>
                                                        <a class="ml-2" @click.stop="see_plan = true;">See Program</a>
                                                    </div>
                                                    <i class="fas fa-chevron-down"></i>
                                                </h4>
                                                <div class="details">
                                                    <div class="blocks">
                                                        <swiper :slidesPerView="'auto'" :spaceBetween="10" :navigation="true" :auto-height="true">
                                                            <swiper-slide>
                                                                <div class="block_title">
                                                                    Warm-up
                                                                </div>
                                                                <div class="plan_list">
                                                                    <span class="block_type">Perform as a Circuit</span>
                                                                    <h6>
                                                                        <span>3 rounds</span>
                                                                        <span>30 sec work</span>
                                                                        <span>20 sec rest</span>
                                                                    </h6>
                                                                    <div class="item_list">
                                                                        <div class="block_item">
                                                                            <div class="video_area">
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-thumbnail/Cardio/Skater-Hops.png" at="Skater Hops">
                                                                            </div>
                                                                            <div class="details_info">
                                                                                <h5>Skater Hops</h5>
                                                                                <h6>
                                                                                    <span>30 sec</span>
                                                                                    <span>Bodyweight</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="block_item">
                                                                            <div class="video_area">
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-thumbnail/Cardio/Side-to-Side-Jump-Rope--male-_Cardio_.png" at="Side to Side Jump Rope (male) Cardio">
                                                                            </div>
                                                                            <div class="details_info">
                                                                                <h5>Side to Side Jump Rope (male) Cardio</h5>
                                                                                <h6>
                                                                                    <span>30 sec</span>
                                                                                    <span>Bodyweight</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="block_item">
                                                                            <div class="video_area">
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-thumbnail/Abs---Core/Obliques---Completed/Elbow-to-Knee-Sit-up.png" at="Elbow to Knee Sit up">
                                                                            </div>
                                                                            <div class="details_info">
                                                                                <h5>Elbow to Knee Sit up</h5>
                                                                                <h6>
                                                                                    <span>30 sec</span>
                                                                                    <span>Bodyweight</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="block_item">
                                                                            <div class="video_area">
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-thumbnail/Hamstring/Hamstrings/Weighted-Stretch-Lunge_Thighs.png" at="Weighted Stretch Lunge Thighs">
                                                                            </div>
                                                                            <div class="details_info">
                                                                                <h5>Weighted Stretch Lunge Thighs</h5>
                                                                                <h6>
                                                                                    <span>30 sec</span>
                                                                                    <span>135 lbs</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </swiper-slide>
                                                            <swiper-slide>
                                                                <div class="block_title">
                                                                    Warm-up 2
                                                                </div>
                                                                <div class="plan_list">
                                                                    <span class="block_type">Perform as a Circuit</span>
                                                                    <h6>
                                                                        <span>3 rounds</span>
                                                                        <span>30 sec work</span>
                                                                        <span>20 sec rest</span>
                                                                    </h6>
                                                                    <div class="item_list">
                                                                        <div class="block_item">
                                                                            <div class="video_area">
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-thumbnail/Cardio/Skater-Hops.png" at="Skater Hops">
                                                                            </div>
                                                                            <div class="details_info">
                                                                                <h5>Skater Hops</h5>
                                                                                <h6>
                                                                                    <span>30 sec</span>
                                                                                    <span>Bodyweight</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="block_item">
                                                                            <div class="video_area">
                                                                                <img src="https://storage.googleapis.com/thrivecoach/workout-thumbnail/Cardio/Side-to-Side-Jump-Rope--male-_Cardio_.png" at="Side to Side Jump Rope (male) Cardio">
                                                                            </div>
                                                                            <div class="details_info">
                                                                                <h5>Side to Side Jump Rope (male) Cardio</h5>
                                                                                <h6>
                                                                                    <span>30 sec</span>
                                                                                    <span>Bodyweight</span>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </swiper-slide>
                                                        </swiper>
                                                    </div>
                                                    <div class="footer_action">
                                                        <!-- <a class="m-0" @click.self="see_plan = true;">See Program</a> -->
                                                        <a class="redirect_btn" href="https://reachvine.com/PFwTCLYx" target="_blank">Complete Now<i class="fas fa-long-arrow-alt-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="view_plan" :class="{'show' : see_plan}">
                                    <div class="header">
                                        <button class="close_btn" @click="see_plan = false;"><i class="fas fa-chevron-down"></i></button>
                                        <h2>View Program</h2>
                                    </div>
                                    <div class="cell">
                                        <div class="content_area">
                                            <PlanView :clr="{accent_clr : '#2f7eed', timer_clr : '#f2a31d', btn_clr : '#2f7eed', btn_txt_clr : '#fff', block_bg_clr: '#eff5ff'}" />
                                        </div>
                                    </div>
                                </div>
                                <template v-for="(module, m) of calendarEventsByDate.morning" :key="m">
                                    <h3 class="sub_heading2">{{ module.title }} <i class="fas fa-caret-down"></i></h3>
                                    <ul class="workout_card show" v-if="module.type == 'habit'">
                                        <li class="show" v-for="(event, e) of module.events" :key="e">
                                            <div class="card_wrapper">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"></path>
                                                </svg>
                                                <div class="info_wpr">
                                                    <h4 @click="toggleDetails($event)">{{  event.name  }}<span class="tag">Schedule: {{ moment(event.time).format('LT') }}</span><i class="fas fa-chevron-down"></i></h4>
                                                    <div class="details">
                                                        <div class="blocks" v-for="(questions, block) of event.questions" :key="block">
                                                            <div class="block_title">{{ block  }}</div>
                                                            <ol class="todo_list">
                                                                <li v-for="(question, q) of questions" :key="q">
                                                                    <label>{{ ++q }}</label>
                                                                    <div class="task_ans">
                                                                        {{ question  }}
                                                                        <div v-if="event.recurring_type == 2" class="yes_no_box" :class="{ disabled: event.completed }">
                                                                            <span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span>
                                                                            <span class="no_box"><i class="fas fa-thumbs-down"></i>No</span>
                                                                        </div>
                                                                        <div v-if="event.recurring_type == 3" class="yes_no_box" :class="{ disabled: event.completed }">
                                                                            <ul class="days">
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Mon</span>
                                                                                    <label for="4161day-0" class="active">
                                                                                        <input type="checkbox" disabled id="4161day-0" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Tue</span>
                                                                                    <label for="4161day-1" class="">
                                                                                        <input type="checkbox" disabled id="4161day-1" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Wed</span>
                                                                                    <label for="4161day-2" class="">
                                                                                        <input type="checkbox" disabled id="4161day-2" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Thu</span>
                                                                                    <label for="4161day-3" class="">
                                                                                        <input type="checkbox" disabled id="4161day-3" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Fri</span>
                                                                                    <label for="4161day-4" class="">
                                                                                        <input type="checkbox" disabled id="4161day-4" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Sat</span>
                                                                                    <label for="4161day-5" class="">
                                                                                        <input type="checkbox" disabled id="4161day-5" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Sun</span>
                                                                                    <label for="4161day-6" class="">
                                                                                        <input type="checkbox" disabled id="4161day-6" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <a class="redirect_btn" v-if="event.upcoming">
                                                            Available at {{ moment(event.time).format('LT') }}
                                                        </a>
                                                        <a v-else class="redirect_btn"  href="javascript:void(0);">
                                                            Complete Now <i class="fas fa-long-arrow-alt-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="workout_card show" v-else-if="module.type == 'progress'">
                                        <li class="show" v-for="(event, e) of module.events" :key="e">
                                            <div class="card_wrapper">
                                                <svg width="85" height="94" viewBox="0 0 85 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M33.7222 90.7778H51.2778C73.2222 90.7778 82 82 82 60.0556V33.7222C82 11.7778 73.2222 3 51.2778 3H33.7222C11.7778 3 3 11.7778 3 33.7222V60.0556C3 82 11.7778 90.7778 33.7222 90.7778Z" stroke="#999999" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M66.0833 26.6063C52.9606 14.9319 33.1228 14.9319 20 26.6063L29.5678 41.9672C37.2483 35.1206 48.835 35.1206 56.5156 41.9672L66.0833 26.6063Z" stroke="#999999" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M41.8105 24.6412C41.8368 24.2798 42.1377 24 42.5 24V24C42.8623 24 43.1632 24.2798 43.1895 24.6412L46.6878 72.7097C46.8564 75.027 45.0218 77 42.6983 77H42.3017C39.9782 77 38.1436 75.027 38.3122 72.7097L41.8105 24.6412Z" fill="#999999"/>
                                                </svg>
                                                <div class="info_wpr">
                                                    <h4 @click="toggleDetails($event)">{{ event.name }} <span class="tag">Schedule: {{ moment(event.time).format('LT') }}</span><i class="fas fa-chevron-down"></i></h4>
                                                    <div class="details">
                                                        <div class="blocks" v-for="(questions, block) of event.questions" :key="block">
                                                            <div class="block_title">{{ block }}</div>
                                                            <ol class="todo_list">
                                                                <li v-for="(question, q) of questions" :key="q">
                                                                    <label>{{ ++q }}</label>
                                                                    <div class="task_ans">{{ question }} <!--<span class="ans_text">120 Pounds</span>--></div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <a class="redirect_btn" v-if="event.upcoming">
                                                            Available at {{ moment(event.time).format('LT') }}
                                                        </a>
                                                        <a v-else class="redirect_btn" href="javascript:void(0)">
                                                            Complete Now <i class="fas fa-long-arrow-alt-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                        <div class="slot_content">
                            <div class="slot_label afternoon" @click="toggleList($event)">
                                <svg class="afternoon" width="130" height="131" viewBox="0 0 130 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36.8678 42.5524C26.5633 55.7964 26.7438 79.3235 39.9134 90.9863C54.866 104.22 75.9099 104.082 89.1433 91.9095C105.008 77.3072 104.096 53.0054 90.0665 39.2414C75.963 25.4031 50.7591 24.6815 36.8678 42.5524Z" fill="#e9e9e9"/>
                                    <path d="M64.0243 19.5452C63.0373 21.3918 63.3663 22.8138 65.3296 23.2807C67.2928 23.7477 79.5074 25.7321 80.5368 25.7958C82.0331 25.8913 82.5849 24.7664 82.4045 23.4611C82.2135 22.1558 79.2315 3.87107 78.9556 2.19435C78.6797 0.517631 76.9074 -1.15909 75.2201 1.08008C73.5328 3.31924 64.7671 18.155 64.0243 19.5452Z" fill="#e9e9e9"/>
                                    <path d="M95.6484 32.2374C93.7276 32.8317 93.7807 34.0096 94.8101 35.5059C95.8394 37.0022 102.928 47.816 103.576 48.5695C104.234 49.3123 105.348 49.5034 106.282 48.3785C107.216 47.2642 118.868 30.9215 119.717 29.9027C121.118 28.226 120.279 26.4537 118.136 27.0056C116.501 27.4407 96.8582 31.866 95.6484 32.2374Z" fill="#e9e9e9"/>
                                    <path d="M110.293 63.6812C108.914 62.8959 107.12 63.1188 106.653 64.891C106.187 66.6632 104.138 79.5358 103.947 80.6607C103.756 81.7856 104.605 82.8998 105.996 82.6239C107.386 82.348 126.636 79.4933 127.92 79.175C130.159 78.6125 130.902 76.3734 128.578 74.6966C127.071 73.6036 111.599 64.4241 110.293 63.6812Z" fill="#e9e9e9"/>
                                    <path d="M94.8952 94.1806C96.381 93.3847 97.6969 93.9896 98.0683 95.6769C98.4397 97.3536 102.547 114.524 103.205 117.697C103.534 119.289 101.708 120.594 100.223 119.66C98.7262 118.727 82.5109 107.425 81.4709 106.501C79.9746 105.196 81.0145 103.838 81.7468 103.148C83.2325 101.736 93.4944 94.934 94.8952 94.1806Z" fill="#e9e9e9"/>
                                    <path d="M49.7405 103.604C47.8621 103.424 47.3103 105.101 47.5968 106.586C47.8727 108.083 50.6743 127.004 50.8654 128.415C51.1413 130.464 53.2955 131.313 54.6857 129.254C56.0759 127.195 65.6693 111.85 66.444 110.407C67.8448 107.796 66.8154 107.234 65.4146 106.586C64.0244 105.939 51.7037 103.795 49.7405 103.604Z" fill="#e9e9e9"/>
                                    <path d="M22.8809 81.8704C24.1862 79.7268 25.8629 80.2892 27.0833 82.3374C27.9111 83.7382 34.9151 93.1618 35.573 94.1805C36.231 95.2099 36.5069 97.1626 34.4588 97.6295C32.3894 98.107 15.6965 102.49 13.0859 102.957C10.4753 103.424 8.97897 101.376 10.6557 99.1364C11.632 97.8205 22.1381 83.0908 22.8809 81.8704Z" fill="#e9e9e9"/>
                                    <path d="M19.3366 65.6339C21.7243 66.6951 22.8492 66.313 23.348 64.6045C24.0059 62.3653 25.9586 52.1034 26.0541 49.7687C26.1177 48.2511 25.4916 46.7867 23.1569 47.1581C20.8223 47.5295 4.03382 49.8748 1.89017 50.6176C-0.603691 51.4878 -0.423285 54.2258 1.23221 55.096C3.19546 56.1254 17.6492 64.891 19.3366 65.6339Z" fill="#e9e9e9"/>
                                    <path d="M48.3502 26.4538C49.6979 25.382 49.2841 23.8432 48.0743 23.0048C46.8645 22.1665 31.8801 11.3315 29.9805 9.84575C28.3038 8.54046 26.2451 9.00739 26.8075 11.9894C27.2108 14.1331 31.9862 32.8954 32.3152 34.1901C32.6866 35.6864 33.8115 36.4292 35.1168 35.4954C36.4009 34.5721 46.9494 27.5787 48.3502 26.4538Z" fill="#e9e9e9"/>
                                </svg>
                                <h2>{{ clientPortal.calendar_settings.afternoon_text }}</h2>
                                <i class="fas fa-chevron-down"></i>
                            </div>
                            <div class="toggle_content">
                                <template v-for="(module, m) of calendarEventsByDate.afternoon" :key="m">
                                    <h3 class="sub_heading2">{{ module.title }} <i class="fas fa-caret-down"></i></h3>
                                    <ul class="workout_card show" v-if="module.type == 'habit'">
                                        <li class="show" v-for="(event, e) of module.events" :key="e">
                                            <div class="card_wrapper">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"></path>
                                                </svg>
                                                <div class="info_wpr">
                                                    <h4 @click="toggleDetails($event)">{{  event.name  }}<span class="tag">Schedule: {{ moment(event.time).format('LT') }}</span><i class="fas fa-chevron-down"></i></h4>
                                                    <div class="details">
                                                        <div class="blocks" v-for="(questions, block) of event.questions" :key="block">
                                                            <div class="block_title">{{ block  }}</div>
                                                            <ol class="todo_list">
                                                                <li v-for="(question, q) of questions" :key="q">
                                                                    <label>{{ ++q }}</label>
                                                                    <div class="task_ans">
                                                                        {{ question  }}
                                                                        <div v-if="event.recurring_type == 2" class="yes_no_box" :class="{ disabled: event.completed }">
                                                                            <span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span>
                                                                            <span class="no_box"><i class="fas fa-thumbs-down"></i>No</span>
                                                                        </div>
                                                                        <div v-if="event.recurring_type == 3" class="yes_no_box" :class="{ disabled: event.completed }">
                                                                            <ul class="days">
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Mon</span>
                                                                                    <label for="4161day-0" class="active">
                                                                                        <input type="checkbox" disabled id="4161day-0" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Tue</span>
                                                                                    <label for="4161day-1" class="">
                                                                                        <input type="checkbox" disabled id="4161day-1" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Wed</span>
                                                                                    <label for="4161day-2" class="">
                                                                                        <input type="checkbox" disabled id="4161day-2" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Thu</span>
                                                                                    <label for="4161day-3" class="">
                                                                                        <input type="checkbox" disabled id="4161day-3" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Fri</span>
                                                                                    <label for="4161day-4" class="">
                                                                                        <input type="checkbox" disabled id="4161day-4" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Sat</span>
                                                                                    <label for="4161day-5" class="">
                                                                                        <input type="checkbox" disabled id="4161day-5" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Sun</span>
                                                                                    <label for="4161day-6" class="">
                                                                                        <input type="checkbox" disabled id="4161day-6" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <a class="redirect_btn" v-if="event.upcoming">
                                                            Available at {{ moment(event.time).format('LT') }}
                                                        </a>
                                                        <a v-else class="redirect_btn"  href="javascript:void(0);">
                                                            Complete Now <i class="fas fa-long-arrow-alt-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="workout_card show" v-else-if="module.type == 'progress'">
                                        <li class="show" v-for="(event, e) of module.events" :key="e">
                                            <div class="card_wrapper">
                                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.25 8.29004C14.26 5.63004 9.74 5.63004 6.75 8.29004L8.93 11.79C10.68 10.23 13.32 10.23 15.07 11.79L17.25 8.29004Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <div class="info_wpr">
                                                    <h4 @click="toggleDetails($event)">{{ event.name }} <span class="tag">Schedule: {{ moment(event.time).format('LT') }}</span><i class="fas fa-chevron-down"></i></h4>
                                                    <div class="details">
                                                        <div class="blocks" v-for="(questions, block) of event.questions" :key="block">
                                                            <div class="block_title">{{ block }}</div>
                                                            <ol class="todo_list">
                                                                <li v-for="(question, q) of questions" :key="q">
                                                                    <label>{{ ++q }}</label>
                                                                    <div class="task_ans">{{ question }} <!--<span class="ans_text">120 Pounds</span>--></div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <a class="redirect_btn" v-if="event.upcoming">
                                                            Available at {{ moment(event.time).format('LT') }}
                                                        </a>
                                                        <a v-else class="redirect_btn" href="javascript:void(0)">
                                                            Complete Now <i class="fas fa-long-arrow-alt-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                        <div class="slot_content">
                            <div class="slot_label evening" @click="toggleList($event)">
                                <svg class="evening" width="149" height="139" viewBox="0 0 149 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M138.857 11.6433C138.506 10.5822 137.554 9.80412 136.397 9.6485L128.313 8.53086L124.71 1.6128C124.197 0.622484 123.143 0 122 0C120.858 0 119.803 0.622484 119.291 1.6128L115.688 8.53086L107.604 9.6485C106.461 9.80412 105.509 10.5822 105.143 11.6433C104.792 12.7043 105.099 13.8786 105.934 14.6567L111.748 20.0185L110.372 27.5732C110.167 28.6625 110.635 29.7802 111.558 30.431C112.481 31.0959 113.711 31.1808 114.736 30.6715L121.986 27.0639L129.235 30.6715C129.675 30.8837 130.143 30.9969 130.627 30.9969C131.256 30.9969 131.886 30.7988 132.413 30.431C133.336 29.7802 133.805 28.6625 133.6 27.5732L132.223 20.0185L138.037 14.6567C138.901 13.8786 139.209 12.7043 138.857 11.6433Z" fill="#e9e9e9"/>
                                    <path d="M33.8574 68.6433C33.5059 67.5822 32.5539 66.8041 31.3969 66.6485L23.3126 65.5309L19.7097 58.6128C19.1971 57.6225 18.1427 57 17.0003 57C15.8579 57 14.8035 57.6225 14.2909 58.6128L10.688 65.5309L2.60367 66.6485C1.46131 66.8041 0.509343 67.5822 0.143202 68.6433C-0.208292 69.7043 0.0992691 70.8786 0.934069 71.6567L6.74837 77.0185L5.37169 84.5732C5.16665 85.6625 5.63531 86.7802 6.55798 87.431C7.48065 88.0959 8.71088 88.1808 9.73608 87.6715L16.9857 84.0639L24.2352 87.6715C24.6746 87.8837 25.1433 87.9969 25.6266 87.9969C26.2563 87.9969 26.8861 87.7988 27.4133 87.431C28.336 86.7802 28.8047 85.6625 28.5996 84.5732L27.2229 77.0185L33.0372 71.6567C33.9013 70.8786 34.2089 69.7043 33.8574 68.6433Z" fill="#e9e9e9"/>
                                    <path d="M36.9287 21.0094C36.753 20.4618 36.277 20.0602 35.6985 19.9799L31.6563 19.403L29.8549 15.8324C29.5986 15.3213 29.0713 15 28.5001 15C27.929 15 27.4017 15.3213 27.1454 15.8324L25.344 19.403L21.3018 19.9799C20.7307 20.0602 20.2547 20.4618 20.0716 21.0094C19.8959 21.5571 20.0496 22.1631 20.467 22.5647L23.3742 25.3321L22.6858 29.2313C22.5833 29.7936 22.8177 30.3704 23.279 30.7063C23.7403 31.0495 24.3554 31.0933 24.868 30.8304L28.4928 28.9685L32.1176 30.8304C32.3373 30.94 32.5716 30.9984 32.8133 30.9984C33.1282 30.9984 33.443 30.8962 33.7067 30.7063C34.168 30.3704 34.4023 29.7936 34.2998 29.2313L33.6115 25.3321L36.5186 22.5647C36.9507 22.1631 37.1044 21.5571 36.9287 21.0094Z" fill="#e9e9e9"/>
                                    <path d="M143.178 106.716C139.75 107.5 136.206 107.892 132.662 107.892C106.475 107.892 85.167 86.5379 85.167 60.3184C85.167 50.6792 88.0204 41.409 93.428 33.4993C94.5325 31.8851 94.5325 29.7405 93.428 28.1032C92.3235 26.4659 90.3445 25.6819 88.4116 26.12C75.9855 28.9334 64.7101 35.9668 56.6561 45.9518C48.5102 56.0522 44 68.8276 44 81.8567C44 97.1226 49.9369 111.466 60.7061 122.258C71.4753 133.05 85.8113 139 101.045 139C119.891 139 137.494 129.684 148.172 114.072C149.276 112.458 149.276 110.313 148.172 108.676C147.067 107.062 145.088 106.277 143.178 106.716Z" fill="#e9e9e9"/>
                                </svg>
                                <h2>{{ clientPortal.calendar_settings.evening_text }}</h2>
                                <i class="fas fa-chevron-down"></i>
                            </div>
                            <div class="toggle_content">
                                <template v-for="(module, m) of calendarEventsByDate.evening" :key="m">
                                    <h3 class="sub_heading2">{{ module.title }} <i class="fas fa-caret-down"></i></h3>
                                    <ul class="workout_card show" v-if="module.type == 'habit'">
                                        <li class="show" v-for="(event, e) of module.events" :key="e">
                                            <div class="card_wrapper">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"></path>
                                                </svg>
                                                <div class="info_wpr">
                                                    <h4 @click="toggleDetails($event)">{{  event.name  }}<span class="tag">Schedule: {{ moment(event.time).format('LT') }}</span><i class="fas fa-chevron-down"></i></h4>
                                                    <div class="details">
                                                        <div class="blocks" v-for="(questions, block) of event.questions" :key="block">
                                                            <div class="block_title">{{ block  }}</div>
                                                            <ol class="todo_list">
                                                                <li v-for="(question, q) of questions" :key="q">
                                                                    <label>{{ ++q }}</label>
                                                                    <div class="task_ans">
                                                                        {{ question  }}
                                                                        <div v-if="event.recurring_type == 2" class="yes_no_box" :class="{ disabled: event.completed }">
                                                                            <span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span>
                                                                            <span class="no_box"><i class="fas fa-thumbs-down"></i>No</span>
                                                                        </div>
                                                                        <div v-if="event.recurring_type == 3" class="yes_no_box" :class="{ disabled: event.completed }">
                                                                            <ul class="days">
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Mon</span>
                                                                                    <label for="4161day-0" class="active">
                                                                                        <input type="checkbox" disabled id="4161day-0" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Tue</span>
                                                                                    <label for="4161day-1" class="">
                                                                                        <input type="checkbox" disabled id="4161day-1" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Wed</span>
                                                                                    <label for="4161day-2" class="">
                                                                                        <input type="checkbox" disabled id="4161day-2" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Thu</span>
                                                                                    <label for="4161day-3" class="">
                                                                                        <input type="checkbox" disabled id="4161day-3" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Fri</span>
                                                                                    <label for="4161day-4" class="">
                                                                                        <input type="checkbox" disabled id="4161day-4" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Sat</span>
                                                                                    <label for="4161day-5" class="">
                                                                                        <input type="checkbox" disabled id="4161day-5" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                                <li class="w-40">
                                                                                    <span class="weekdays">Sun</span>
                                                                                    <label for="4161day-6" class="">
                                                                                        <input type="checkbox" disabled id="4161day-6" true-value="1" false-value="0" hidden="">
                                                                                    </label>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <a class="redirect_btn" v-if="event.upcoming">
                                                            Available at {{ moment(event.time).format('LT') }}
                                                        </a>
                                                        <a v-else class="redirect_btn"  href="javascript:void(0);">
                                                            Complete Now <i class="fas fa-long-arrow-alt-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="workout_card show" v-else-if="module.type == 'progress'">
                                        <li class="show" v-for="(event, e) of module.events" :key="e">
                                            <div class="card_wrapper">
                                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.25 8.29004C14.26 5.63004 9.74 5.63004 6.75 8.29004L8.93 11.79C10.68 10.23 13.32 10.23 15.07 11.79L17.25 8.29004Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <div class="info_wpr">
                                                    <h4 @click="toggleDetails($event)">{{ event.name }} <span class="tag">Schedule: {{ moment(event.time).format('LT') }}</span><i class="fas fa-chevron-down"></i></h4>
                                                    <div class="details">
                                                        <div class="blocks" v-for="(questions, block) of event.questions" :key="block">
                                                            <div class="block_title">{{ block }}</div>
                                                            <ol class="todo_list">
                                                                <li v-for="(question, q) of questions" :key="q">
                                                                    <label>{{ ++q }}</label>
                                                                    <div class="task_ans">{{ question }} <!--<span class="ans_text">120 Pounds</span>--></div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <a class="redirect_btn" v-if="event.upcoming">
                                                            Available at {{ moment(event.time).format('LT') }}
                                                        </a>
                                                        <a v-else class="redirect_btn" href="javascript:void(0)">
                                                            Complete Now <i class="fas fa-long-arrow-alt-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="showEventDrawer = false">Cancel</button>
                </div>
            </div>
        </div>
        <sort-asset v-model="sortPlaybook" title="Playbook" asset-type="playbooks" :update-sorting="sortingFilter" :folder-id="0" :folder-scope="0" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import Draggable from 'vuedraggable'
    import SwiperCore, { Navigation, Pagination } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation, Pagination])

    const SettingComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/Setting'))
    const CoverSetting = defineAsyncComponent(() => import('@/pages/client-portal/components/CoverSetting'))
    const HeaderSetting = defineAsyncComponent(() => import('@/pages/client-portal/components/HeaderSetting'))
    const ClientBoard = defineAsyncComponent(() => import('@/pages/client-portal/components/ClientBoard'))
    const PortalNavigation = defineAsyncComponent(() => import('@/pages/client-portal/components/PortalNavigation'))
    const PlaybookComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/Playbook'))
    const PageComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/Page'))
    const FormComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/Form'))
    const ChartsComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/chart/Index'))
    const WorkoutComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/Workout'))
    const WorkoutPlanComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/WorkoutPlan'))
    const FileComponent = defineAsyncComponent(() => import('@/pages/client-portal/components/File'))
    const LoginPreview = defineAsyncComponent(() => import('@/pages/client-portal/components/LoginPreview'))
    const PortalPreview = defineAsyncComponent(() => import('@/pages/client-portal/components/PortalPreview'))
    const FooterSetting = defineAsyncComponent(() => import('@/pages/client-portal/components/FooterSetting'))
    const Chat = defineAsyncComponent(() => import('@/components/Chat'))
    const PlanView = defineAsyncComponent(() => import('@/pages/client-portal/components/PlanView'))
    const WelcomeSetting = defineAsyncComponent(() => import('@/pages/client-portal/components/WelcomeSetting'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const AnnouncementSettings = defineAsyncComponent(() => import('@/pages/client-portal/components/AnnouncementSettings'))
    const TaskSettings = defineAsyncComponent(() => import('@/pages/client-portal/components/TaskSettings'))

    import moment from 'moment-timezone'
    import FullCalendar from '@fullcalendar/vue3'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import timeGridPlugin from '@fullcalendar/timegrid'

    export default {
        name: 'ClientPortal',

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                previewPortal: false,
                previewLogin: false,
                portalSetting: false,
                coverSetting: false,
                footerSetting: false,
                headerSetting: false,
                navigationTab: 'home',
                classroomTab: 'playbooks',
                showWelcomeVideo: false,
                loadedContent: false,
                chat: false,
                coverImage: '',
                form: {
                    display_cover: 1,
                    cover_type: 2,
                    cover: '',
                    headline: 'WELCOME TO',
                    description: 'YOUR CLIENT PORTAL',
                    overlay_color: '',
                    opac_overlay_color: '5',
                    pinned_widget: 1,
                    welcome_widget: 1,
                    has_welcome_video: 1,
                    has_announcement: 1,
                    has_today_tasks: 0,
                    activity_widget: 1,
                    announcement_widget: 1,
                    cover_background_color: '#2F7FED',
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgb(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgb(0, 0, 0, 0.68)',
                    },
                    habit_score_color: '#2F7FED',
                    progress_score_color: '#F2A31D',
                    login_score_color: '#95D7EF',
                    referral_score_color: '#999999',
                    is_headline: 1,
                    is_subheadline: 1,
                },
                calendarEventsToggle: false,
                completed_view: false,
                ev_type: '',
                selected_date: '',
                showCalendar: true,
                see_plan: false,
                filterList: false,
                searchField: false,
                params: {
                    per_page: 8,
                    page: 1,
                    category_id: null,
                    search: '',
                },
                isTyping: false,
                is_complete_btn : 0,
                calendarOptions: {
                    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                    editable: false,
                    firstDay: 1,
                    initialView: 'dayGridMonth',
                    headerToolbar: {
                        left: 'prev,next',
                        center: 'title',
                        right: 'dayGridDay dayGridWeek dayGridMonth complete incomplete'
                    },
                    customButtons: {
                        complete: {
                            text: 'Complete',
                            click: this.customBtnClick
                        },
                        incomplete: {
                            text: 'Incomplete',
                            click: this.customBtnClick
                        },
                        today: {
                            text: 'Today',
                            click: () => {
                                const calendar = this.$refs['portalCalendar'];

                                calendar.calendar.today();
                            }
                        },
                    },
                    dayMaxEvents: 2,
                    events: [],
                    height: "auto",
                    contentHeight: "auto",
                    views: {
                        dayGridMonth: {
                            eventContent: function(arg) {
                                let el = document.createElement('label');
                                let el2 = document.createElement('div');
                                el2.classList.add('quick_info');

                                if (arg.event.extendedProps.events) {
                                    let totalEvents = 0;

                                    if (arg.event.extendedProps.events.morning && arg.event.extendedProps.events.morning.length) {
                                        totalEvents += arg.event.extendedProps.events.morning.length;
                                    }

                                    if (arg.event.extendedProps.events.afternoon && arg.event.extendedProps.events.afternoon.length) {
                                        totalEvents += arg.event.extendedProps.events.afternoon.length;
                                    }

                                    if (arg.event.extendedProps.events.evening && arg.event.extendedProps.events.evening.length) {
                                        totalEvents += arg.event.extendedProps.events.evening.length;
                                    }

                                    el.innerHTML = `${totalEvents} Event${totalEvents > 1 ? 's' : ''}`;

                                    Object.keys(arg.event.extendedProps.events).forEach((group, index) => {
                                        if (group == 'morning') {
                                            el2.innerHTML += `<div class="event_info event-group-title ${group}">
                                                                <svg class="morning" width="150" height="76" viewBox="0 0 150 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M46.2473 44.5521C60.1386 26.6813 85.3425 27.4029 99.4461 41.2411C105.594 47.2729 109.223 55.3284 110.025 63.7045C110.088 64.3579 109.492 64.8725 108.848 64.7481C78.7707 58.9419 51.5654 62.091 40.322 64.6799C39.6618 64.8319 39.0417 64.3087 39.1041 63.6341C39.7566 56.5804 42.1886 49.7686 46.2473 44.5521Z" fill="#e9e9e9"/>
                                                                    <path d="M0 76C23.3193 69.906 84.5238 61.5505 150 76H0Z" fill="#e9e9e9"/>
                                                                    <path d="M92.8793 25.942C91.2045 27.1984 90.9017 28.6262 92.4836 29.8791C94.0656 31.132 104.297 38.0927 105.203 38.5854C106.519 39.3043 107.494 38.518 107.883 37.2588C108.261 35.9951 113.286 18.1632 113.744 16.527C114.203 14.8907 113.305 12.6221 110.83 13.9384C108.354 15.2547 94.1401 24.996 92.8793 25.942Z" fill="#e9e9e9"/>
                                                                    <path d="M112.462 45.5531C110.451 45.5558 110.155 46.6971 110.699 48.4301C111.242 50.1631 114.834 62.5843 115.231 63.495C115.641 64.3985 116.65 64.9091 117.874 64.1088C119.094 63.3188 135.04 51.1292 136.151 50.4054C137.984 49.2153 137.704 47.2748 135.493 47.1713C133.803 47.1061 113.727 45.5542 112.462 45.5531Z" fill="#e9e9e9"/>
                                                                    <path d="M64.7444 22.2849C64.0584 24.2631 64.6058 25.6162 66.618 25.7702C68.6301 25.9243 81.0047 25.9735 82.0314 25.8754C83.5242 25.7357 83.8933 24.5383 83.5109 23.2773C83.1181 22.0179 77.3124 4.4248 76.7776 2.81188C76.2428 1.19896 74.2301 -0.179877 72.9138 2.29568C71.5975 4.77123 65.2606 20.7956 64.7444 22.2849Z" fill="#e9e9e9"/>
                                                                    <path d="M30.3418 63.4812C32.6732 64.6609 33.8159 64.3358 34.3998 62.6544C35.1693 60.4511 37.6345 50.3001 37.847 47.9731C37.9868 46.4607 37.4349 44.9666 35.0846 45.2204C32.7342 45.4742 15.8492 46.974 13.671 47.6083C11.1366 48.3523 11.1793 51.0958 12.7891 52.048C14.6982 53.1746 28.6938 62.6546 30.3418 63.4812Z" fill="#e9e9e9"/>
                                                                    <path d="M56.368 28.3441C57.6172 27.1589 57.0708 25.662 55.7926 24.9323C54.5143 24.2026 38.6427 14.7148 36.6208 13.4003C34.8367 12.2461 32.8265 12.8907 33.6467 15.8123C34.2352 17.9127 40.6278 36.1874 41.0684 37.4484C41.5688 38.9067 42.7541 39.5487 43.9731 38.5046C45.1718 37.4729 55.0706 29.5868 56.368 28.3441Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                <h5>${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}</h5>
                                                            </div>`;
                                        } else if (group == 'afternoon') {
                                            el2.innerHTML += `<div class="event_info event-group-title ${group}">
                                                                <svg class="afternoon" width="130" height="131" viewBox="0 0 130 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M36.8678 42.5524C26.5633 55.7964 26.7438 79.3235 39.9134 90.9863C54.866 104.22 75.9099 104.082 89.1433 91.9095C105.008 77.3072 104.096 53.0054 90.0665 39.2414C75.963 25.4031 50.7591 24.6815 36.8678 42.5524Z" fill="#e9e9e9"/>
                                                                    <path d="M64.0243 19.5452C63.0373 21.3918 63.3663 22.8138 65.3296 23.2807C67.2928 23.7477 79.5074 25.7321 80.5368 25.7958C82.0331 25.8913 82.5849 24.7664 82.4045 23.4611C82.2135 22.1558 79.2315 3.87107 78.9556 2.19435C78.6797 0.517631 76.9074 -1.15909 75.2201 1.08008C73.5328 3.31924 64.7671 18.155 64.0243 19.5452Z" fill="#e9e9e9"/>
                                                                    <path d="M95.6484 32.2374C93.7276 32.8317 93.7807 34.0096 94.8101 35.5059C95.8394 37.0022 102.928 47.816 103.576 48.5695C104.234 49.3123 105.348 49.5034 106.282 48.3785C107.216 47.2642 118.868 30.9215 119.717 29.9027C121.118 28.226 120.279 26.4537 118.136 27.0056C116.501 27.4407 96.8582 31.866 95.6484 32.2374Z" fill="#e9e9e9"/>
                                                                    <path d="M110.293 63.6812C108.914 62.8959 107.12 63.1188 106.653 64.891C106.187 66.6632 104.138 79.5358 103.947 80.6607C103.756 81.7856 104.605 82.8998 105.996 82.6239C107.386 82.348 126.636 79.4933 127.92 79.175C130.159 78.6125 130.902 76.3734 128.578 74.6966C127.071 73.6036 111.599 64.4241 110.293 63.6812Z" fill="#e9e9e9"/>
                                                                    <path d="M94.8952 94.1806C96.381 93.3847 97.6969 93.9896 98.0683 95.6769C98.4397 97.3536 102.547 114.524 103.205 117.697C103.534 119.289 101.708 120.594 100.223 119.66C98.7262 118.727 82.5109 107.425 81.4709 106.501C79.9746 105.196 81.0145 103.838 81.7468 103.148C83.2325 101.736 93.4944 94.934 94.8952 94.1806Z" fill="#e9e9e9"/>
                                                                    <path d="M49.7405 103.604C47.8621 103.424 47.3103 105.101 47.5968 106.586C47.8727 108.083 50.6743 127.004 50.8654 128.415C51.1413 130.464 53.2955 131.313 54.6857 129.254C56.0759 127.195 65.6693 111.85 66.444 110.407C67.8448 107.796 66.8154 107.234 65.4146 106.586C64.0244 105.939 51.7037 103.795 49.7405 103.604Z" fill="#e9e9e9"/>
                                                                    <path d="M22.8809 81.8704C24.1862 79.7268 25.8629 80.2892 27.0833 82.3374C27.9111 83.7382 34.9151 93.1618 35.573 94.1805C36.231 95.2099 36.5069 97.1626 34.4588 97.6295C32.3894 98.107 15.6965 102.49 13.0859 102.957C10.4753 103.424 8.97897 101.376 10.6557 99.1364C11.632 97.8205 22.1381 83.0908 22.8809 81.8704Z" fill="#e9e9e9"/>
                                                                    <path d="M19.3366 65.6339C21.7243 66.6951 22.8492 66.313 23.348 64.6045C24.0059 62.3653 25.9586 52.1034 26.0541 49.7687C26.1177 48.2511 25.4916 46.7867 23.1569 47.1581C20.8223 47.5295 4.03382 49.8748 1.89017 50.6176C-0.603691 51.4878 -0.423285 54.2258 1.23221 55.096C3.19546 56.1254 17.6492 64.891 19.3366 65.6339Z" fill="#e9e9e9"/>
                                                                    <path d="M48.3502 26.4538C49.6979 25.382 49.2841 23.8432 48.0743 23.0048C46.8645 22.1665 31.8801 11.3315 29.9805 9.84575C28.3038 8.54046 26.2451 9.00739 26.8075 11.9894C27.2108 14.1331 31.9862 32.8954 32.3152 34.1901C32.6866 35.6864 33.8115 36.4292 35.1168 35.4954C36.4009 34.5721 46.9494 27.5787 48.3502 26.4538Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                <h5>${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}</h5>
                                                            </div>`;
                                        } else if (group == 'evening') {
                                            el2.innerHTML += `<div class="event_info event-group-title ${group}">
                                                                <svg class="evening" width="149" height="139" viewBox="0 0 149 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M138.857 11.6433C138.506 10.5822 137.554 9.80412 136.397 9.6485L128.313 8.53086L124.71 1.6128C124.197 0.622484 123.143 0 122 0C120.858 0 119.803 0.622484 119.291 1.6128L115.688 8.53086L107.604 9.6485C106.461 9.80412 105.509 10.5822 105.143 11.6433C104.792 12.7043 105.099 13.8786 105.934 14.6567L111.748 20.0185L110.372 27.5732C110.167 28.6625 110.635 29.7802 111.558 30.431C112.481 31.0959 113.711 31.1808 114.736 30.6715L121.986 27.0639L129.235 30.6715C129.675 30.8837 130.143 30.9969 130.627 30.9969C131.256 30.9969 131.886 30.7988 132.413 30.431C133.336 29.7802 133.805 28.6625 133.6 27.5732L132.223 20.0185L138.037 14.6567C138.901 13.8786 139.209 12.7043 138.857 11.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M33.8574 68.6433C33.5059 67.5822 32.5539 66.8041 31.3969 66.6485L23.3126 65.5309L19.7097 58.6128C19.1971 57.6225 18.1427 57 17.0003 57C15.8579 57 14.8035 57.6225 14.2909 58.6128L10.688 65.5309L2.60367 66.6485C1.46131 66.8041 0.509343 67.5822 0.143202 68.6433C-0.208292 69.7043 0.0992691 70.8786 0.934069 71.6567L6.74837 77.0185L5.37169 84.5732C5.16665 85.6625 5.63531 86.7802 6.55798 87.431C7.48065 88.0959 8.71088 88.1808 9.73608 87.6715L16.9857 84.0639L24.2352 87.6715C24.6746 87.8837 25.1433 87.9969 25.6266 87.9969C26.2563 87.9969 26.8861 87.7988 27.4133 87.431C28.336 86.7802 28.8047 85.6625 28.5996 84.5732L27.2229 77.0185L33.0372 71.6567C33.9013 70.8786 34.2089 69.7043 33.8574 68.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M36.9287 21.0094C36.753 20.4618 36.277 20.0602 35.6985 19.9799L31.6563 19.403L29.8549 15.8324C29.5986 15.3213 29.0713 15 28.5001 15C27.929 15 27.4017 15.3213 27.1454 15.8324L25.344 19.403L21.3018 19.9799C20.7307 20.0602 20.2547 20.4618 20.0716 21.0094C19.8959 21.5571 20.0496 22.1631 20.467 22.5647L23.3742 25.3321L22.6858 29.2313C22.5833 29.7936 22.8177 30.3704 23.279 30.7063C23.7403 31.0495 24.3554 31.0933 24.868 30.8304L28.4928 28.9685L32.1176 30.8304C32.3373 30.94 32.5716 30.9984 32.8133 30.9984C33.1282 30.9984 33.443 30.8962 33.7067 30.7063C34.168 30.3704 34.4023 29.7936 34.2998 29.2313L33.6115 25.3321L36.5186 22.5647C36.9507 22.1631 37.1044 21.5571 36.9287 21.0094Z" fill="#e9e9e9"/>
                                                                    <path d="M143.178 106.716C139.75 107.5 136.206 107.892 132.662 107.892C106.475 107.892 85.167 86.5379 85.167 60.3184C85.167 50.6792 88.0204 41.409 93.428 33.4993C94.5325 31.8851 94.5325 29.7405 93.428 28.1032C92.3235 26.4659 90.3445 25.6819 88.4116 26.12C75.9855 28.9334 64.7101 35.9668 56.6561 45.9518C48.5102 56.0522 44 68.8276 44 81.8567C44 97.1226 49.9369 111.466 60.7061 122.258C71.4753 133.05 85.8113 139 101.045 139C119.891 139 137.494 129.684 148.172 114.072C149.276 112.458 149.276 110.313 148.172 108.676C147.067 107.062 145.088 106.277 143.178 106.716Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                <h5>${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}</h5>
                                                            </div>`;
                                        }

                                        arg.event.extendedProps.events[group].forEach((item, i) => {
                                            el2.innerHTML += `<div class="event_info"><span>${i + 1}</span><h5>${item.desc}</h5></div>` 
                                        })
                                    });
                                }

                                let arrayOfDomNodes = [ el, el2 ];

                                return { domNodes: arrayOfDomNodes};
                            }
                        },
                        dayGridWeek: {
                            eventContent: function(arg) {
                                let el = document.createElement('div');
                                el.classList.add('activity_list');

                                if (arg.event.extendedProps.events) {
                                    Object.keys(arg.event.extendedProps.events).forEach((group, index) => {
                                        if (group == 'morning') {
                                            el.innerHTML += `<div class="list_item event-group-title ${group}">
                                                                <svg class="morning" width="150" height="76" viewBox="0 0 150 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M46.2473 44.5521C60.1386 26.6813 85.3425 27.4029 99.4461 41.2411C105.594 47.2729 109.223 55.3284 110.025 63.7045C110.088 64.3579 109.492 64.8725 108.848 64.7481C78.7707 58.9419 51.5654 62.091 40.322 64.6799C39.6618 64.8319 39.0417 64.3087 39.1041 63.6341C39.7566 56.5804 42.1886 49.7686 46.2473 44.5521Z" fill="#e9e9e9"/>
                                                                    <path d="M0 76C23.3193 69.906 84.5238 61.5505 150 76H0Z" fill="#e9e9e9"/>
                                                                    <path d="M92.8793 25.942C91.2045 27.1984 90.9017 28.6262 92.4836 29.8791C94.0656 31.132 104.297 38.0927 105.203 38.5854C106.519 39.3043 107.494 38.518 107.883 37.2588C108.261 35.9951 113.286 18.1632 113.744 16.527C114.203 14.8907 113.305 12.6221 110.83 13.9384C108.354 15.2547 94.1401 24.996 92.8793 25.942Z" fill="#e9e9e9"/>
                                                                    <path d="M112.462 45.5531C110.451 45.5558 110.155 46.6971 110.699 48.4301C111.242 50.1631 114.834 62.5843 115.231 63.495C115.641 64.3985 116.65 64.9091 117.874 64.1088C119.094 63.3188 135.04 51.1292 136.151 50.4054C137.984 49.2153 137.704 47.2748 135.493 47.1713C133.803 47.1061 113.727 45.5542 112.462 45.5531Z" fill="#e9e9e9"/>
                                                                    <path d="M64.7444 22.2849C64.0584 24.2631 64.6058 25.6162 66.618 25.7702C68.6301 25.9243 81.0047 25.9735 82.0314 25.8754C83.5242 25.7357 83.8933 24.5383 83.5109 23.2773C83.1181 22.0179 77.3124 4.4248 76.7776 2.81188C76.2428 1.19896 74.2301 -0.179877 72.9138 2.29568C71.5975 4.77123 65.2606 20.7956 64.7444 22.2849Z" fill="#e9e9e9"/>
                                                                    <path d="M30.3418 63.4812C32.6732 64.6609 33.8159 64.3358 34.3998 62.6544C35.1693 60.4511 37.6345 50.3001 37.847 47.9731C37.9868 46.4607 37.4349 44.9666 35.0846 45.2204C32.7342 45.4742 15.8492 46.974 13.671 47.6083C11.1366 48.3523 11.1793 51.0958 12.7891 52.048C14.6982 53.1746 28.6938 62.6546 30.3418 63.4812Z" fill="#e9e9e9"/>
                                                                    <path d="M56.368 28.3441C57.6172 27.1589 57.0708 25.662 55.7926 24.9323C54.5143 24.2026 38.6427 14.7148 36.6208 13.4003C34.8367 12.2461 32.8265 12.8907 33.6467 15.8123C34.2352 17.9127 40.6278 36.1874 41.0684 37.4484C41.5688 38.9067 42.7541 39.5487 43.9731 38.5046C45.1718 37.4729 55.0706 29.5868 56.368 28.3441Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                ${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}
                                                            </div>`;
                                        } else if (group == 'afternoon') {
                                            el.innerHTML += `<div class="list_item event-group-title ${group}">
                                                                <svg class="afternoon" width="130" height="131" viewBox="0 0 130 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M36.8678 42.5524C26.5633 55.7964 26.7438 79.3235 39.9134 90.9863C54.866 104.22 75.9099 104.082 89.1433 91.9095C105.008 77.3072 104.096 53.0054 90.0665 39.2414C75.963 25.4031 50.7591 24.6815 36.8678 42.5524Z" fill="#e9e9e9"/>
                                                                    <path d="M64.0243 19.5452C63.0373 21.3918 63.3663 22.8138 65.3296 23.2807C67.2928 23.7477 79.5074 25.7321 80.5368 25.7958C82.0331 25.8913 82.5849 24.7664 82.4045 23.4611C82.2135 22.1558 79.2315 3.87107 78.9556 2.19435C78.6797 0.517631 76.9074 -1.15909 75.2201 1.08008C73.5328 3.31924 64.7671 18.155 64.0243 19.5452Z" fill="#e9e9e9"/>
                                                                    <path d="M95.6484 32.2374C93.7276 32.8317 93.7807 34.0096 94.8101 35.5059C95.8394 37.0022 102.928 47.816 103.576 48.5695C104.234 49.3123 105.348 49.5034 106.282 48.3785C107.216 47.2642 118.868 30.9215 119.717 29.9027C121.118 28.226 120.279 26.4537 118.136 27.0056C116.501 27.4407 96.8582 31.866 95.6484 32.2374Z" fill="#e9e9e9"/>
                                                                    <path d="M110.293 63.6812C108.914 62.8959 107.12 63.1188 106.653 64.891C106.187 66.6632 104.138 79.5358 103.947 80.6607C103.756 81.7856 104.605 82.8998 105.996 82.6239C107.386 82.348 126.636 79.4933 127.92 79.175C130.159 78.6125 130.902 76.3734 128.578 74.6966C127.071 73.6036 111.599 64.4241 110.293 63.6812Z" fill="#e9e9e9"/>
                                                                    <path d="M94.8952 94.1806C96.381 93.3847 97.6969 93.9896 98.0683 95.6769C98.4397 97.3536 102.547 114.524 103.205 117.697C103.534 119.289 101.708 120.594 100.223 119.66C98.7262 118.727 82.5109 107.425 81.4709 106.501C79.9746 105.196 81.0145 103.838 81.7468 103.148C83.2325 101.736 93.4944 94.934 94.8952 94.1806Z" fill="#e9e9e9"/>
                                                                    <path d="M49.7405 103.604C47.8621 103.424 47.3103 105.101 47.5968 106.586C47.8727 108.083 50.6743 127.004 50.8654 128.415C51.1413 130.464 53.2955 131.313 54.6857 129.254C56.0759 127.195 65.6693 111.85 66.444 110.407C67.8448 107.796 66.8154 107.234 65.4146 106.586C64.0244 105.939 51.7037 103.795 49.7405 103.604Z" fill="#e9e9e9"/>
                                                                    <path d="M22.8809 81.8704C24.1862 79.7268 25.8629 80.2892 27.0833 82.3374C27.9111 83.7382 34.9151 93.1618 35.573 94.1805C36.231 95.2099 36.5069 97.1626 34.4588 97.6295C32.3894 98.107 15.6965 102.49 13.0859 102.957C10.4753 103.424 8.97897 101.376 10.6557 99.1364C11.632 97.8205 22.1381 83.0908 22.8809 81.8704Z" fill="#e9e9e9"/>
                                                                    <path d="M19.3366 65.6339C21.7243 66.6951 22.8492 66.313 23.348 64.6045C24.0059 62.3653 25.9586 52.1034 26.0541 49.7687C26.1177 48.2511 25.4916 46.7867 23.1569 47.1581C20.8223 47.5295 4.03382 49.8748 1.89017 50.6176C-0.603691 51.4878 -0.423285 54.2258 1.23221 55.096C3.19546 56.1254 17.6492 64.891 19.3366 65.6339Z" fill="#e9e9e9"/>
                                                                    <path d="M48.3502 26.4538C49.6979 25.382 49.2841 23.8432 48.0743 23.0048C46.8645 22.1665 31.8801 11.3315 29.9805 9.84575C28.3038 8.54046 26.2451 9.00739 26.8075 11.9894C27.2108 14.1331 31.9862 32.8954 32.3152 34.1901C32.6866 35.6864 33.8115 36.4292 35.1168 35.4954C36.4009 34.5721 46.9494 27.5787 48.3502 26.4538Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                ${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}
                                                            </div>`;
                                        } else if (group == 'evening') {
                                            el.innerHTML += `<div class="list_item event-group-title ${group}">
                                                                <svg class="evening" width="149" height="139" viewBox="0 0 149 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M138.857 11.6433C138.506 10.5822 137.554 9.80412 136.397 9.6485L128.313 8.53086L124.71 1.6128C124.197 0.622484 123.143 0 122 0C120.858 0 119.803 0.622484 119.291 1.6128L115.688 8.53086L107.604 9.6485C106.461 9.80412 105.509 10.5822 105.143 11.6433C104.792 12.7043 105.099 13.8786 105.934 14.6567L111.748 20.0185L110.372 27.5732C110.167 28.6625 110.635 29.7802 111.558 30.431C112.481 31.0959 113.711 31.1808 114.736 30.6715L121.986 27.0639L129.235 30.6715C129.675 30.8837 130.143 30.9969 130.627 30.9969C131.256 30.9969 131.886 30.7988 132.413 30.431C133.336 29.7802 133.805 28.6625 133.6 27.5732L132.223 20.0185L138.037 14.6567C138.901 13.8786 139.209 12.7043 138.857 11.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M33.8574 68.6433C33.5059 67.5822 32.5539 66.8041 31.3969 66.6485L23.3126 65.5309L19.7097 58.6128C19.1971 57.6225 18.1427 57 17.0003 57C15.8579 57 14.8035 57.6225 14.2909 58.6128L10.688 65.5309L2.60367 66.6485C1.46131 66.8041 0.509343 67.5822 0.143202 68.6433C-0.208292 69.7043 0.0992691 70.8786 0.934069 71.6567L6.74837 77.0185L5.37169 84.5732C5.16665 85.6625 5.63531 86.7802 6.55798 87.431C7.48065 88.0959 8.71088 88.1808 9.73608 87.6715L16.9857 84.0639L24.2352 87.6715C24.6746 87.8837 25.1433 87.9969 25.6266 87.9969C26.2563 87.9969 26.8861 87.7988 27.4133 87.431C28.336 86.7802 28.8047 85.6625 28.5996 84.5732L27.2229 77.0185L33.0372 71.6567C33.9013 70.8786 34.2089 69.7043 33.8574 68.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M36.9287 21.0094C36.753 20.4618 36.277 20.0602 35.6985 19.9799L31.6563 19.403L29.8549 15.8324C29.5986 15.3213 29.0713 15 28.5001 15C27.929 15 27.4017 15.3213 27.1454 15.8324L25.344 19.403L21.3018 19.9799C20.7307 20.0602 20.2547 20.4618 20.0716 21.0094C19.8959 21.5571 20.0496 22.1631 20.467 22.5647L23.3742 25.3321L22.6858 29.2313C22.5833 29.7936 22.8177 30.3704 23.279 30.7063C23.7403 31.0495 24.3554 31.0933 24.868 30.8304L28.4928 28.9685L32.1176 30.8304C32.3373 30.94 32.5716 30.9984 32.8133 30.9984C33.1282 30.9984 33.443 30.8962 33.7067 30.7063C34.168 30.3704 34.4023 29.7936 34.2998 29.2313L33.6115 25.3321L36.5186 22.5647C36.9507 22.1631 37.1044 21.5571 36.9287 21.0094Z" fill="#e9e9e9"/>
                                                                    <path d="M143.178 106.716C139.75 107.5 136.206 107.892 132.662 107.892C106.475 107.892 85.167 86.5379 85.167 60.3184C85.167 50.6792 88.0204 41.409 93.428 33.4993C94.5325 31.8851 94.5325 29.7405 93.428 28.1032C92.3235 26.4659 90.3445 25.6819 88.4116 26.12C75.9855 28.9334 64.7101 35.9668 56.6561 45.9518C48.5102 56.0522 44 68.8276 44 81.8567C44 97.1226 49.9369 111.466 60.7061 122.258C71.4753 133.05 85.8113 139 101.045 139C119.891 139 137.494 129.684 148.172 114.072C149.276 112.458 149.276 110.313 148.172 108.676C147.067 107.062 145.088 106.277 143.178 106.716Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                ${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}
                                                            </div>`;
                                        }

                                        arg.event.extendedProps.events[group].forEach((item, i) => {
                                            if (item.type === 'workout') {
                                                el.innerHTML += `<div class="list_item">
                                                    <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                                    <path d="M17.8241 12.7621C12.3284 15.7511 11.2758 21.3192 11.4365 23.7296C11.7981 27.9478 15.5342 31.0814 16.9805 32.1661C18.1375 33.0338 19.7123 32.5276 20.3551 32.1661C26.8632 28.671 31.5636 27.4657 41.0848 27.3452C50.6059 27.2247 57.9578 31.684 59.5245 32.4071C60.778 32.9856 61.7341 32.6482 62.0555 32.4071C66.4907 29.4182 67.8405 25.3767 67.961 23.7296C68.5636 15.6547 60.7297 12.4006 60.0066 12.1595C59.4281 11.9667 57.8372 11.8381 57.1141 11.798C47.7134 11.1954 22.886 11.798 21.0782 11.798C19.632 11.798 18.3062 12.4408 17.8241 12.7621Z" stroke="#999999" stroke-width="5"/>
                                                    <path d="M32.1058 47.5454V72.9999H28.2528V51.3984H28.1036L22.0134 55.3757V51.6967L28.3646 47.5454H32.1058Z" fill="#858585"/>
                                                    <path d="M47.8533 73.4225C45.8895 73.4142 44.2116 72.8964 42.8195 71.8689C41.4275 70.8414 40.3627 69.3458 39.6253 67.382C38.8878 65.4183 38.5191 63.0526 38.5191 60.2851C38.5191 57.5259 38.8878 55.1685 39.6253 53.213C40.371 51.2575 41.4399 49.766 42.832 48.7386C44.2323 47.7111 45.9061 47.1974 47.8533 47.1974C49.8005 47.1974 51.4701 47.7153 52.8622 48.751C54.2542 49.7785 55.3189 51.2699 56.0564 53.2254C56.8021 55.1726 57.175 57.5259 57.175 60.2851C57.175 63.0609 56.8063 65.4307 56.0688 67.3945C55.3314 69.35 54.2666 70.8456 52.8746 71.8813C51.4825 72.9088 49.8088 73.4225 47.8533 73.4225ZM47.8533 70.104C49.5768 70.104 50.9232 69.263 51.8927 67.5809C52.8704 65.8989 53.3593 63.4669 53.3593 60.2851C53.3593 58.1722 53.1356 56.3865 52.6881 54.9282C52.249 53.4616 51.6151 52.3513 50.7865 51.5972C49.9662 50.8349 48.9885 50.4538 47.8533 50.4538C46.1381 50.4538 44.7916 51.2989 43.8139 52.9893C42.8361 54.6796 42.3431 57.1116 42.3348 60.2851C42.3348 62.4063 42.5544 64.2002 42.9935 65.6668C43.441 67.1252 44.0749 68.2314 44.8952 68.9854C45.7155 69.7311 46.7015 70.104 47.8533 70.104Z" fill="#858585"/>
                                                </svg><strong>Workouts:</strong>${item.desc}</div>`;
                                            } else if(item.type === 'event') {
                                                el.innerHTML += `<div class="list_item">
                                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="1" y="7.03442" width="38.3448" height="32.9655" rx="3" stroke="#999999" stroke-width="2"/>
                                                    <rect x="7.60693" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                    <rect x="28.5862" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                    <circle cx="11.8" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="11.8" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="20.4155" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="20.4155" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="29.2463" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="29.2463" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                </svg><strong>Meetings:</strong>${item.desc}</div>`;
                                            } else if(item.type === 'progress') {
                                                el.innerHTML += `<div class="list_item"><svg width="85" height="94" viewBox="0 0 85 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M33.7222 90.7778H51.2778C73.2222 90.7778 82 82 82 60.0556V33.7222C82 11.7778 73.2222 3 51.2778 3H33.7222C11.7778 3 3 11.7778 3 33.7222V60.0556C3 82 11.7778 90.7778 33.7222 90.7778Z" stroke="#999999" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M66.0833 26.6063C52.9606 14.9319 33.1228 14.9319 20 26.6063L29.5678 41.9672C37.2483 35.1206 48.835 35.1206 56.5156 41.9672L66.0833 26.6063Z" stroke="#999999" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M41.8105 24.6412C41.8368 24.2798 42.1377 24 42.5 24V24C42.8623 24 43.1632 24.2798 43.1895 24.6412L46.6878 72.7097C46.8564 75.027 45.0218 77 42.6983 77H42.3017C39.9782 77 38.1436 75.027 38.3122 72.7097L41.8105 24.6412Z" fill="#999999"/>
                                                </svg><strong>Progress:</strong>${item.desc}</div>`;
                                            } else if (item.type === 'habit') {
                                                el.innerHTML += `<div class="list_item"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"/>
                                                </svg><strong>Habits:</strong>${item.desc}</div>`;
                                            }
                                        })
                                    })
                                }

                                let arrayOfDomNodes = [ el ];
                                return { domNodes: arrayOfDomNodes};
                            }
                        },
                        dayGridDay: {
                            eventContent: function(arg) {
                                let el = document.createElement('div');
                                el.classList.add('activity_list');

                                if (arg.event.extendedProps.events) {
                                    Object.keys(arg.event.extendedProps.events).forEach((group, index) => {
                                        if (group == 'morning') {
                                            el.innerHTML += `<div class="list_item event-group-title ${group}">
                                                                <svg class="morning" width="150" height="76" viewBox="0 0 150 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M46.2473 44.5521C60.1386 26.6813 85.3425 27.4029 99.4461 41.2411C105.594 47.2729 109.223 55.3284 110.025 63.7045C110.088 64.3579 109.492 64.8725 108.848 64.7481C78.7707 58.9419 51.5654 62.091 40.322 64.6799C39.6618 64.8319 39.0417 64.3087 39.1041 63.6341C39.7566 56.5804 42.1886 49.7686 46.2473 44.5521Z" fill="#e9e9e9"/>
                                                                    <path d="M0 76C23.3193 69.906 84.5238 61.5505 150 76H0Z" fill="#e9e9e9"/>
                                                                    <path d="M92.8793 25.942C91.2045 27.1984 90.9017 28.6262 92.4836 29.8791C94.0656 31.132 104.297 38.0927 105.203 38.5854C106.519 39.3043 107.494 38.518 107.883 37.2588C108.261 35.9951 113.286 18.1632 113.744 16.527C114.203 14.8907 113.305 12.6221 110.83 13.9384C108.354 15.2547 94.1401 24.996 92.8793 25.942Z" fill="#e9e9e9"/>
                                                                    <path d="M112.462 45.5531C110.451 45.5558 110.155 46.6971 110.699 48.4301C111.242 50.1631 114.834 62.5843 115.231 63.495C115.641 64.3985 116.65 64.9091 117.874 64.1088C119.094 63.3188 135.04 51.1292 136.151 50.4054C137.984 49.2153 137.704 47.2748 135.493 47.1713C133.803 47.1061 113.727 45.5542 112.462 45.5531Z" fill="#e9e9e9"/>
                                                                    <path d="M64.7444 22.2849C64.0584 24.2631 64.6058 25.6162 66.618 25.7702C68.6301 25.9243 81.0047 25.9735 82.0314 25.8754C83.5242 25.7357 83.8933 24.5383 83.5109 23.2773C83.1181 22.0179 77.3124 4.4248 76.7776 2.81188C76.2428 1.19896 74.2301 -0.179877 72.9138 2.29568C71.5975 4.77123 65.2606 20.7956 64.7444 22.2849Z" fill="#e9e9e9"/>
                                                                    <path d="M30.3418 63.4812C32.6732 64.6609 33.8159 64.3358 34.3998 62.6544C35.1693 60.4511 37.6345 50.3001 37.847 47.9731C37.9868 46.4607 37.4349 44.9666 35.0846 45.2204C32.7342 45.4742 15.8492 46.974 13.671 47.6083C11.1366 48.3523 11.1793 51.0958 12.7891 52.048C14.6982 53.1746 28.6938 62.6546 30.3418 63.4812Z" fill="#e9e9e9"/>
                                                                    <path d="M56.368 28.3441C57.6172 27.1589 57.0708 25.662 55.7926 24.9323C54.5143 24.2026 38.6427 14.7148 36.6208 13.4003C34.8367 12.2461 32.8265 12.8907 33.6467 15.8123C34.2352 17.9127 40.6278 36.1874 41.0684 37.4484C41.5688 38.9067 42.7541 39.5487 43.9731 38.5046C45.1718 37.4729 55.0706 29.5868 56.368 28.3441Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                ${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}
                                                            </div>`;
                                        } else if (group == 'afternoon') {
                                            el.innerHTML += `<div class="list_item event-group-title ${group}">
                                                                <svg class="afternoon" width="130" height="131" viewBox="0 0 130 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M36.8678 42.5524C26.5633 55.7964 26.7438 79.3235 39.9134 90.9863C54.866 104.22 75.9099 104.082 89.1433 91.9095C105.008 77.3072 104.096 53.0054 90.0665 39.2414C75.963 25.4031 50.7591 24.6815 36.8678 42.5524Z" fill="#e9e9e9"/>
                                                                    <path d="M64.0243 19.5452C63.0373 21.3918 63.3663 22.8138 65.3296 23.2807C67.2928 23.7477 79.5074 25.7321 80.5368 25.7958C82.0331 25.8913 82.5849 24.7664 82.4045 23.4611C82.2135 22.1558 79.2315 3.87107 78.9556 2.19435C78.6797 0.517631 76.9074 -1.15909 75.2201 1.08008C73.5328 3.31924 64.7671 18.155 64.0243 19.5452Z" fill="#e9e9e9"/>
                                                                    <path d="M95.6484 32.2374C93.7276 32.8317 93.7807 34.0096 94.8101 35.5059C95.8394 37.0022 102.928 47.816 103.576 48.5695C104.234 49.3123 105.348 49.5034 106.282 48.3785C107.216 47.2642 118.868 30.9215 119.717 29.9027C121.118 28.226 120.279 26.4537 118.136 27.0056C116.501 27.4407 96.8582 31.866 95.6484 32.2374Z" fill="#e9e9e9"/>
                                                                    <path d="M110.293 63.6812C108.914 62.8959 107.12 63.1188 106.653 64.891C106.187 66.6632 104.138 79.5358 103.947 80.6607C103.756 81.7856 104.605 82.8998 105.996 82.6239C107.386 82.348 126.636 79.4933 127.92 79.175C130.159 78.6125 130.902 76.3734 128.578 74.6966C127.071 73.6036 111.599 64.4241 110.293 63.6812Z" fill="#e9e9e9"/>
                                                                    <path d="M94.8952 94.1806C96.381 93.3847 97.6969 93.9896 98.0683 95.6769C98.4397 97.3536 102.547 114.524 103.205 117.697C103.534 119.289 101.708 120.594 100.223 119.66C98.7262 118.727 82.5109 107.425 81.4709 106.501C79.9746 105.196 81.0145 103.838 81.7468 103.148C83.2325 101.736 93.4944 94.934 94.8952 94.1806Z" fill="#e9e9e9"/>
                                                                    <path d="M49.7405 103.604C47.8621 103.424 47.3103 105.101 47.5968 106.586C47.8727 108.083 50.6743 127.004 50.8654 128.415C51.1413 130.464 53.2955 131.313 54.6857 129.254C56.0759 127.195 65.6693 111.85 66.444 110.407C67.8448 107.796 66.8154 107.234 65.4146 106.586C64.0244 105.939 51.7037 103.795 49.7405 103.604Z" fill="#e9e9e9"/>
                                                                    <path d="M22.8809 81.8704C24.1862 79.7268 25.8629 80.2892 27.0833 82.3374C27.9111 83.7382 34.9151 93.1618 35.573 94.1805C36.231 95.2099 36.5069 97.1626 34.4588 97.6295C32.3894 98.107 15.6965 102.49 13.0859 102.957C10.4753 103.424 8.97897 101.376 10.6557 99.1364C11.632 97.8205 22.1381 83.0908 22.8809 81.8704Z" fill="#e9e9e9"/>
                                                                    <path d="M19.3366 65.6339C21.7243 66.6951 22.8492 66.313 23.348 64.6045C24.0059 62.3653 25.9586 52.1034 26.0541 49.7687C26.1177 48.2511 25.4916 46.7867 23.1569 47.1581C20.8223 47.5295 4.03382 49.8748 1.89017 50.6176C-0.603691 51.4878 -0.423285 54.2258 1.23221 55.096C3.19546 56.1254 17.6492 64.891 19.3366 65.6339Z" fill="#e9e9e9"/>
                                                                    <path d="M48.3502 26.4538C49.6979 25.382 49.2841 23.8432 48.0743 23.0048C46.8645 22.1665 31.8801 11.3315 29.9805 9.84575C28.3038 8.54046 26.2451 9.00739 26.8075 11.9894C27.2108 14.1331 31.9862 32.8954 32.3152 34.1901C32.6866 35.6864 33.8115 36.4292 35.1168 35.4954C36.4009 34.5721 46.9494 27.5787 48.3502 26.4538Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                ${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}
                                                            </div>`;
                                        } else if (group == 'evening') {
                                            el.innerHTML += `<div class="list_item event-group-title ${group}">
                                                                <svg class="evening" width="149" height="139" viewBox="0 0 149 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M138.857 11.6433C138.506 10.5822 137.554 9.80412 136.397 9.6485L128.313 8.53086L124.71 1.6128C124.197 0.622484 123.143 0 122 0C120.858 0 119.803 0.622484 119.291 1.6128L115.688 8.53086L107.604 9.6485C106.461 9.80412 105.509 10.5822 105.143 11.6433C104.792 12.7043 105.099 13.8786 105.934 14.6567L111.748 20.0185L110.372 27.5732C110.167 28.6625 110.635 29.7802 111.558 30.431C112.481 31.0959 113.711 31.1808 114.736 30.6715L121.986 27.0639L129.235 30.6715C129.675 30.8837 130.143 30.9969 130.627 30.9969C131.256 30.9969 131.886 30.7988 132.413 30.431C133.336 29.7802 133.805 28.6625 133.6 27.5732L132.223 20.0185L138.037 14.6567C138.901 13.8786 139.209 12.7043 138.857 11.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M33.8574 68.6433C33.5059 67.5822 32.5539 66.8041 31.3969 66.6485L23.3126 65.5309L19.7097 58.6128C19.1971 57.6225 18.1427 57 17.0003 57C15.8579 57 14.8035 57.6225 14.2909 58.6128L10.688 65.5309L2.60367 66.6485C1.46131 66.8041 0.509343 67.5822 0.143202 68.6433C-0.208292 69.7043 0.0992691 70.8786 0.934069 71.6567L6.74837 77.0185L5.37169 84.5732C5.16665 85.6625 5.63531 86.7802 6.55798 87.431C7.48065 88.0959 8.71088 88.1808 9.73608 87.6715L16.9857 84.0639L24.2352 87.6715C24.6746 87.8837 25.1433 87.9969 25.6266 87.9969C26.2563 87.9969 26.8861 87.7988 27.4133 87.431C28.336 86.7802 28.8047 85.6625 28.5996 84.5732L27.2229 77.0185L33.0372 71.6567C33.9013 70.8786 34.2089 69.7043 33.8574 68.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M36.9287 21.0094C36.753 20.4618 36.277 20.0602 35.6985 19.9799L31.6563 19.403L29.8549 15.8324C29.5986 15.3213 29.0713 15 28.5001 15C27.929 15 27.4017 15.3213 27.1454 15.8324L25.344 19.403L21.3018 19.9799C20.7307 20.0602 20.2547 20.4618 20.0716 21.0094C19.8959 21.5571 20.0496 22.1631 20.467 22.5647L23.3742 25.3321L22.6858 29.2313C22.5833 29.7936 22.8177 30.3704 23.279 30.7063C23.7403 31.0495 24.3554 31.0933 24.868 30.8304L28.4928 28.9685L32.1176 30.8304C32.3373 30.94 32.5716 30.9984 32.8133 30.9984C33.1282 30.9984 33.443 30.8962 33.7067 30.7063C34.168 30.3704 34.4023 29.7936 34.2998 29.2313L33.6115 25.3321L36.5186 22.5647C36.9507 22.1631 37.1044 21.5571 36.9287 21.0094Z" fill="#e9e9e9"/>
                                                                    <path d="M143.178 106.716C139.75 107.5 136.206 107.892 132.662 107.892C106.475 107.892 85.167 86.5379 85.167 60.3184C85.167 50.6792 88.0204 41.409 93.428 33.4993C94.5325 31.8851 94.5325 29.7405 93.428 28.1032C92.3235 26.4659 90.3445 25.6819 88.4116 26.12C75.9855 28.9334 64.7101 35.9668 56.6561 45.9518C48.5102 56.0522 44 68.8276 44 81.8567C44 97.1226 49.9369 111.466 60.7061 122.258C71.4753 133.05 85.8113 139 101.045 139C119.891 139 137.494 129.684 148.172 114.072C149.276 112.458 149.276 110.313 148.172 108.676C147.067 107.062 145.088 106.277 143.178 106.716Z" fill="#e9e9e9"/>
                                                                </svg>
                                                                ${group} ${arg.event.extendedProps.events[group].length > 1 ? 'Activities' : 'Activity'}
                                                            </div>`;
                                        }

                                        arg.event.extendedProps.events[group].forEach((item, i) => {
                                            if (item.type === 'workout') {
                                                el.innerHTML += `<div class="list_item">
                                                    <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                                    <path d="M17.8241 12.7621C12.3284 15.7511 11.2758 21.3192 11.4365 23.7296C11.7981 27.9478 15.5342 31.0814 16.9805 32.1661C18.1375 33.0338 19.7123 32.5276 20.3551 32.1661C26.8632 28.671 31.5636 27.4657 41.0848 27.3452C50.6059 27.2247 57.9578 31.684 59.5245 32.4071C60.778 32.9856 61.7341 32.6482 62.0555 32.4071C66.4907 29.4182 67.8405 25.3767 67.961 23.7296C68.5636 15.6547 60.7297 12.4006 60.0066 12.1595C59.4281 11.9667 57.8372 11.8381 57.1141 11.798C47.7134 11.1954 22.886 11.798 21.0782 11.798C19.632 11.798 18.3062 12.4408 17.8241 12.7621Z" stroke="#999999" stroke-width="5"/>
                                                    <path d="M32.1058 47.5454V72.9999H28.2528V51.3984H28.1036L22.0134 55.3757V51.6967L28.3646 47.5454H32.1058Z" fill="#858585"/>
                                                    <path d="M47.8533 73.4225C45.8895 73.4142 44.2116 72.8964 42.8195 71.8689C41.4275 70.8414 40.3627 69.3458 39.6253 67.382C38.8878 65.4183 38.5191 63.0526 38.5191 60.2851C38.5191 57.5259 38.8878 55.1685 39.6253 53.213C40.371 51.2575 41.4399 49.766 42.832 48.7386C44.2323 47.7111 45.9061 47.1974 47.8533 47.1974C49.8005 47.1974 51.4701 47.7153 52.8622 48.751C54.2542 49.7785 55.3189 51.2699 56.0564 53.2254C56.8021 55.1726 57.175 57.5259 57.175 60.2851C57.175 63.0609 56.8063 65.4307 56.0688 67.3945C55.3314 69.35 54.2666 70.8456 52.8746 71.8813C51.4825 72.9088 49.8088 73.4225 47.8533 73.4225ZM47.8533 70.104C49.5768 70.104 50.9232 69.263 51.8927 67.5809C52.8704 65.8989 53.3593 63.4669 53.3593 60.2851C53.3593 58.1722 53.1356 56.3865 52.6881 54.9282C52.249 53.4616 51.6151 52.3513 50.7865 51.5972C49.9662 50.8349 48.9885 50.4538 47.8533 50.4538C46.1381 50.4538 44.7916 51.2989 43.8139 52.9893C42.8361 54.6796 42.3431 57.1116 42.3348 60.2851C42.3348 62.4063 42.5544 64.2002 42.9935 65.6668C43.441 67.1252 44.0749 68.2314 44.8952 68.9854C45.7155 69.7311 46.7015 70.104 47.8533 70.104Z" fill="#858585"/>
                                                </svg><strong>Workouts:</strong>${item.desc}</div>`;
                                            } else if(item.type === 'event') {
                                                el.innerHTML += `<div class="list_item">
                                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="1" y="7.03442" width="38.3448" height="32.9655" rx="3" stroke="#999999" stroke-width="2"/>
                                                    <rect x="7.60693" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                    <rect x="28.5862" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                    <circle cx="11.8" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="11.8" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="20.4155" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="20.4155" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="29.2463" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                    <circle cx="29.2463" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                </svg><strong>Meetings:</strong>${item.desc}</div>`;
                                            } else if(item.type === 'progress') {
                                                el.innerHTML += `<div class="list_item"><svg width="85" height="94" viewBox="0 0 85 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M33.7222 90.7778H51.2778C73.2222 90.7778 82 82 82 60.0556V33.7222C82 11.7778 73.2222 3 51.2778 3H33.7222C11.7778 3 3 11.7778 3 33.7222V60.0556C3 82 11.7778 90.7778 33.7222 90.7778Z" stroke="#999999" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M66.0833 26.6063C52.9606 14.9319 33.1228 14.9319 20 26.6063L29.5678 41.9672C37.2483 35.1206 48.835 35.1206 56.5156 41.9672L66.0833 26.6063Z" stroke="#999999" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M41.8105 24.6412C41.8368 24.2798 42.1377 24 42.5 24V24C42.8623 24 43.1632 24.2798 43.1895 24.6412L46.6878 72.7097C46.8564 75.027 45.0218 77 42.6983 77H42.3017C39.9782 77 38.1436 75.027 38.3122 72.7097L41.8105 24.6412Z" fill="#999999"/>
                                                </svg><strong>Progress:</strong>${item.desc}</div>`;
                                            } else if (item.type === 'habit') {
                                                el.innerHTML += `<div class="list_item"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"/>
                                                </svg><strong>Habits:</strong>${item.desc}</div>`;
                                            }
                                        })
                                    })
                                }

                                let arrayOfDomNodes = [ el ];

                                return { domNodes: arrayOfDomNodes};
                            }
                        }
                    },
                    fixedWeekCount: false,
                    eventClick: this.handleEventClick,
                    datesSet: this.handleMonthChange,
                },
                showEventDrawer: false,
                selectedEventDate: moment().format('ll'),
                toggleAll: 1,
                moment,
                selectedPlaybookCategory: 0,
                welcomeToggle: false,
                welcomeToggleType: 1,
                announcementToggle: false,
                announcementToggleType: 2,
                taskToggle: false,
                taskToggleType: 3,
                sortPlaybook: false,
                chat_expand: false,
                has_new_msg: 1,
                playbookCategoryFilterText: 'Filter',
                draggableWidgets: ['welcome', 'announcement', 'today_tasks'],
            }
        },

        components: {
            SettingComponent,
            CoverSetting,
            HeaderSetting,
            ClientBoard,
            PortalNavigation,
            PlaybookComponent,
            PageComponent,
            FormComponent,
            ChartsComponent,
            WorkoutComponent,
            WorkoutPlanComponent,
            LoginPreview,
            PortalPreview,
            Chat,
            FooterSetting,
            FullCalendar,
            PlanView,
            FileComponent,
            Draggable,
            Swiper,
            SwiperSlide,
            WelcomeSetting,
            SortAsset,
            AnnouncementSettings,
            TaskSettings
        },

        watch: {
            clientPortal () {
                const vm = this;

                vm.resetForm();
            },

            coverImage (cover) {
                const vm = this;

                vm.updatePortalSetting({ cover });
            },

            previewPortal (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else if (val == false && vm.portalSetting) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },

            previewLogin (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else if (val == false && vm.portalSetting) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },

            portalSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            coverSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            footerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            headerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            calendarEventsToggle (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            chat(val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                    this.chat_expand = false;
                }
            },

            calendarEvents (events) {
                const vm = this;

                setTimeout(() => {
                    vm.calendarOptions.events = JSON.parse(JSON.stringify(events));
                }, 500);
            },

            showEventDrawer(val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                    vm.toggleAll = 1;

                    const hour = moment().tz(vm.user.timezone).format('H');
                    let time = 'morning';

                    if (hour < 12 ) {
                        time = 'morning';
                    } else if (hour >= 12 && hour < 17) {
                        time = 'afternoon';
                    } else if (hour >= 17 && hour < 24) {
                        time = 'evening';
                    }

                    setTimeout(() => {
                        document.querySelector(`.slot_label.${time}`).click();
                    }, 500);
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            classroomTab (tab) {
                const vm = this;

                vm.params.search = '';

                if(tab){
                    document.querySelector('.mobile_bar').click();
                }
            },

            navigationTab(val){
                if(val){
                    document.querySelector('.mobile_bar').click();
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            clientPortal: state => state.clientPortalModule.clientPortal,
            clientPortalLoader: state => state.clientPortalModule.clientPortalLoader,
            pinnedPlaybooks: state => state.clientPortalModule.pinnedPlaybooks,
            assetsLoader: state => state.clientPortalModule.assetsLoader,
            updateLoader: state => state.clientPortalModule.updateLoader,
            lastRefreshedAt: state => state.clientPortalModule.lastRefreshedAt,
            playbookCategories: state => state.clientPortalModule.playbookCategories,
            calendarEvents: state => state.clientPortalModule.calendarEvents,
            calendarEventsByDate: state => state.clientPortalModule.calendarEventsByDate,
            calendarEventLoader: state => state.clientPortalModule.calendarEventLoader,
        }),

        mounted () {
            const vm = this;

            if (!vm.clientPortal.id || vm.lastRefreshedAt && moment().diff(vm.lastRefreshedAt, 'hours') > 1) {
                vm.getClientPortal();
            } else {
                vm.resetForm();
            }

            vm.getPlaybooks(vm.params);
            vm.getPinnedPlaybooks();
        },

        methods: {
            ...mapActions({
                getClientPortal: 'clientPortalModule/getClientPortal',
                getPlaybooks: 'clientPortalModule/getPlaybooks',
                getPinnedPlaybooks: 'clientPortalModule/getPinnedPlaybooks',
                updatePortalSetting: 'clientPortalModule/updatePortalSetting',
                getCalendarEvents: 'clientPortalModule/getCalendarEvents',
                getCalendarEventsByDate: 'clientPortalModule/getCalendarEventsByDate',
            }),

            resetForm () {
                const vm = this;
                let headline_setting = vm.clientPortal.headline_setting ? JSON.parse(vm.clientPortal.headline_setting) : {};
                let subheadline_setting = vm.clientPortal.subheadline_setting ? JSON.parse(vm.clientPortal.subheadline_setting) : {};

                vm.loadedContent = false;

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    };
                }

                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    };
                }

                vm.form = {
                    id: vm.clientPortal.id ? vm.clientPortal.id : 0,
                    headline_setting,
                    subheadline_setting,
                    display_cover: vm.clientPortal.display_cover ? vm.clientPortal.display_cover : 0,
                    cover: vm.clientPortal.cover ? vm.clientPortal.cover : '',
                    small_cover: vm.clientPortal.small_cover ? vm.clientPortal.small_cover : '',
                    cover_type: vm.clientPortal.cover_type ? vm.clientPortal.cover_type : 2,
                    headline: vm.clientPortal.headline ? vm.clientPortal.headline : 'Welcome To Your Portal!',
                    description: vm.clientPortal.description ? vm.clientPortal.description : '',
                    overlay_color: vm.clientPortal.overlay_color ? vm.clientPortal.overlay_color : '',
                    opac_overlay_color: vm.clientPortal.opac_overlay_color ? vm.clientPortal.opac_overlay_color : 'rgb(0, 0, 0, 0.05)',
                    has_cover_overlay: vm.clientPortal.has_cover_overlay ? vm.clientPortal.has_cover_overlay : 0,
                    overlay_opacity: vm.clientPortal.overlay_opacity ? vm.clientPortal.overlay_opacity : '5',
                    display_footer: vm.clientPortal.display_footer ? vm.clientPortal.display_footer : 0,
                    footer_company: vm.clientPortal.footer_company ? vm.clientPortal.footer_company : `${new Date().getFullYear()} - Thrive Coach`,
                    white_label: vm.clientPortal.white_label ? vm.clientPortal.white_label : 'Powered By Thrive Coach',
                    pinned_widget: vm.clientPortal.pinned_widget ? vm.clientPortal.pinned_widget : 0,
                    welcome_widget: vm.clientPortal.welcome_widget ? vm.clientPortal.welcome_widget : 0,
                    activity_widget: vm.clientPortal.activity_widget ? vm.clientPortal.activity_widget : 0,
                    announcement_widget: vm.clientPortal.announcement_widget ? vm.clientPortal.announcement_widget : 0,
                    has_welcome_video: vm.clientPortal.has_welcome_video ? vm.clientPortal.has_welcome_video : 0,
                    has_announcement: vm.clientPortal.has_announcement ? vm.clientPortal.has_announcement : 0,
                    has_today_tasks: vm.clientPortal.has_today_tasks ? vm.clientPortal.has_today_tasks : 0,
                    cover_background_color: vm.clientPortal.cover_background_color ? vm.clientPortal.cover_background_color : '#2F7FED',
                    habit_score_color: vm.clientPortal.habit_score_color ? vm.clientPortal.habit_score_color : '#2F7FED',
                    progress_score_color: vm.clientPortal.progress_score_color ? vm.clientPortal.progress_score_color : '#F2A31D',
                    login_score_color: vm.clientPortal.login_score_color ? vm.clientPortal.login_score_color : '#95D7EF',
                    referral_score_color: vm.clientPortal.referral_score_color ? vm.clientPortal.referral_score_color : '#999999',
                    is_headline: vm.clientPortal.is_headline ? vm.clientPortal.is_headline : 0,
                    is_subheadline: vm.clientPortal.is_subheadline ? vm.clientPortal.is_subheadline : 0,
                };

                setTimeout(function () {
                    vm.loadedContent = true;
                }, 1000);

                vm.draggableWidgets = vm.clientPortal.widgets_sorting;
            },

            updatePortal (params) {
                const vm = this;

                if (vm.loadedContent) {
                    vm.updatePortalSetting(params);
                }
            },

            customBtnClick (el) {
                const vm = this;
                let x = el.currentTarget;
                let y = x.parentNode;

                if (x.classList.contains('fc-complete-button')) {
                    vm.is_complete_btn = 1;
                    y.querySelector('.fc-complete-button').classList.add('fc-button-active');
                    y.querySelector('.fc-incomplete-button').classList.remove('fc-button-active');
                } else {
                    vm.is_complete_btn = 0;
                    y.querySelector('.fc-incomplete-button').classList.add('fc-button-active');
                    y.querySelector('.fc-complete-button').classList.remove('fc-button-active');
                }
            },

            closeFilters () {
                const vm = this;

                vm.filterList = false;
            },

            toggleList (ev) {
                const vm = this;
                const el = ev.currentTarget;

                if (el.nextSibling.classList) {
                    if (el.nextSibling.classList.contains('show')) {
                        el.nextSibling.classList.remove('show');
                        el.classList.remove('rotate');
                        vm.toggleAll = 1;
                    } else {
                        el.nextSibling.classList.add('show');
                        el.classList.add('rotate');
                        vm.toggleAll = 0;
                    }
                }
            },

            toggleDetails (ev) {
                const el = ev.currentTarget.closest('li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    el.classList.add('show');
                }
            },

            handleMonthChange (calendar) {
                const vm = this;

                let date = moment().format('MMMM YYYY');

                if (calendar.view.type == 'dayGridMonth') {
                    date = calendar.view.title;
                } else if (calendar.view.type == 'dayGridWeek') {
                    date = moment(calendar.view.currentEnd).format('MMMM YYYY');
                } else if (calendar.view.type == 'dayGridDay') {
                    date = moment(calendar.view.currentEnd).format('MMMM YYYY');
                }

                date = date.toLowerCase();

                vm.getCalendarEvents({ date });
            },

            handleEventClick (info) {
                const vm = this;
                const date = info.event.start;

                vm.showEventDrawer      = true;
                vm.selectedEventDate    = moment(date).format('ll');

                vm.getCalendarEventsByDate({ date });
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.toggle_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.toggle_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            feedbackToggle (ev) {
                const el = ev.currentTarget;
                const nextEl = el.nextSibling;

                if (nextEl.classList.contains('show')) {
                    nextEl.classList.remove('show');
                    el.innerHTML = 'Show Feedback';
                } else {
                    nextEl.classList.add('show');
                    el.innerHTML = 'Hide Feedback';
                }
            },

            toggleExercise (ev) {
                const el = ev.currentTarget;

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    el.classList.add('show');
                }
            },

            handleCategoryFilter (category) {
                const vm = this;

                vm.playbookCategoryFilterText = category.name;

                vm.selectedPlaybookCategory = category.id;
            },


            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            sortingFilter (filter) {
                const vm = this;

                vm.selectedPlaybook = [];

                vm.getPinnedPlaybooks();
                vm.getPlaybooks(vm.params);
            },

            navToggle (ev) {
                const elm = ev.currentTarget.closest('.card_header');

                if (elm.classList.contains('mobile_nav')) {
                    elm.classList.remove('mobile_nav');
                } else {
                    elm.classList.add('mobile_nav');
                }
            },

            handleSortWidgets () {
                const vm = this;

                vm.updatePortal({ widgets_sorting: vm.draggableWidgets, id: vm.form.id })
            },
        },
    }
</script>

<style scoped>
    .section_header {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto 30px auto;
        justify-content: flex-start;
        gap: 0;
    }
    .footer-content{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    .section_header .tutorial_btn{
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
        position: relative;
    }
    .section_header .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
        min-width: auto;
    }
    .section_header .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .section_header .tutorial_btn .dropdown_wpr {
        min-width: 160px;
        z-index: 5;
        overflow: hidden;
    }
    .section_header .tutorial_btn .dropdown_wpr ul li{
        border: 0;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        white-space: nowrap;
    }
    .activity_score{
        border: 0;
        background: #fff;
    }
    .add_announcment{
        color: #5a5a5a;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin: 15px 0 0 auto;
        cursor: pointer;
    }
    .profile_spinner_wpr {
        z-index: 8;
    }
    .dashboard_content{
        margin-bottom: 50px;
    }
    .dashboard_content h3{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 15px;
        justify-content: flex-start;
        align-items: center;
    }
    .dashboard_content h3 .switch_option{
        margin-left: auto;
    }
    .dashboard_content h3 .data-v-tooltip:after{
        font-family: 'Inter',sans-serif;
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        max-width: 250px;
        color: #333;
        background: #fff;
        box-shadow: 0 1em 2em -0.5em rgba(0,0,0,.35);
    }
    .dashboard_content h3 .data-v-tooltip:not([position]):after{
        left: 80%;
    }
    .dashboard_content h3 .data-v-tooltip:not([position]):before,
    .dashboard_content h3 .data-v-tooltip[position^="top"]::before{
        border-top-color: #fff;
    }
    .dashboard_content h3 .data-v-tooltip[position^="right"]::before{
        border-right-color: #fff;
    }
    .dashboard_content h3 .data-v-tooltip[position^="left"]::before{
        border-left-color: #fff;
    }
    .dashboard_content h3 .data-v-tooltip[position^="bottom"]::before{
        border-bottom-color: #fff;
    }

    h3.section_title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #121525;
        margin: 0;
        align-items: center;
    }
    
    .dashboard_content .client_productList{
        margin: 0 -10px;
    }
    .dashboard_content .client_productList li{
        padding: 15px 10px;
    }
    .dashboard_content .right_side .extra_info{
        border: 0;
    }
    .dashboard_content .right_side .extra_info .action_wpr{
        position: relative;
        margin-bottom: -10px;
    }
    :deep(.video_wpr) {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        /* padding-top: 25px; */
        /* margin-bottom: 15px; */
    }

    :deep(.video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .video-overlay {
        position: absolute;
        z-index: +9;
        height: 100%;
        width: 100%;
        top: 0;
        pointer-events: none;
    }

    .playbook-desc h4 {
        height: 40px;
    }

    .playbook-desc p {
        height: 50px;
    }

    :deep(.button-uploader) {
        position: absolute;
        left: 25px;
        top: 15px;
        background: #fff;
        z-index: 3;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
    }
    :deep(.card_body:hover .button-uploader){
        left: 25px;
    }

    :deep(.button-uploader .image_setting) {
        height: 41px;
        width: 41px;
        position: relative;
    }

    :deep(.button-uploader .upload_btn) {
        left: 0;
        top: 0;
    }

    :deep(.upload_btn .select_img) {
        height: 15px !important;
    }

    :deep(.tab_btn li.active) {
        background-color: var(--var-portal-tab-background-color);
        border-color: var(--var-portal-tab-background-color);
        color: var(--var-portal-tab-text-color) !important;
    }

    :deep(.Pagination li button.Page-active) {
        background-color: var(--var-portal-tab-background-color) !important;
        border-color: var(--var-portal-tab-background-color) !important;
        color: var(--var-portal-tab-text-color) !important;
    }

    :deep(.pin.custom-pin i){
        color: #121525;
    }
    :deep(.custom_element) {
        margin: 0 5px;
        letter-spacing: 0;
        background-color: var(--var-portal-tab-background-color) !important;
        border-color: var(--var-portal-tab-background-color) !important;
        color: var(--var-portal-tab-text-color) !important;
    }

    :deep(.chatbox_wpr .chat_box .input_area) {
        background: #FFF;
    }

    /* :deep(.chatbox_wpr .chat_box button), */
    :deep(.chatbox_wpr .chat_box .chat-emoji-picker),
    :deep(.chatbox_wpr .chat_box .dropdown) {
        background: #FFF;
    }

    :deep(.chat_btn) {
        box-shadow: none;
        background: #fff;
    }
    /* .chat_btn:before{
        position: absolute;
        content: '';
        background: var(--var-chat-bg-color90);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    } */
    /* .chat_btn.ripple:before{
        display: none;
    } */
    :deep(.activity_score h5) {
        color: #656565;
    }

    :deep(.client_card .card_body .action){
        right: 13px;
    }

    :deep(.banner_area2 .banner_wpr h1.shadow-none),
    :deep(.banner_area2 .banner_wpr h4.shadow-none),
    :deep(.banner_area3 .banner_wpr h1.shadow-none),
    :deep(.banner_area3 .banner_wpr h4.shadow-none),
    :deep(.banner_area .banner_wpr h1.shadow-none),
    :deep(.banner_area .banner_wpr h4.shadow-none),
    :deep(.banner_wpr .bnrCont_wpr h1.shadow-none),
    :deep(.banner_wpr .bnrCont_wpr h4.shadow-none),
    :deep(.client_card .card_body h1.shadow-none),
    :deep(.client_card .card_body h4.shadow-none) {
        text-shadow: none;
    }
    .draft *{
        opacity: 0.5;
    }

    button.header_setting {
        position: relative;
        margin-left: 15px;
        color: #9f9e9e;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .client_card .card_header .user_box{
        margin-left: auto;
    }

    .client_card .card_header .dashboard-menu-text h4 {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        border: 1px solid #d9d9d9;
        padding: 5px 15px;
        border-radius: 20px;
    }

    .card_header nav {
        margin-right: 0;
        padding-right: 0;
        border: 0;
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 35px;
    }

    /* .cell .logo_img {
        max-height: 25px;
        width: auto;
    } */
    .modal.workout_modal .modal_body{
        padding-top: 0;
        background: #fff;
    }
    /* .workout_list {
        background: #f5f5f5;
        border-bottom: 1px solid #eaeaea;
        margin: 0 -45px;
        padding: 20px 45px 40px 45px;
    } */
    .modal.workout_modal .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: -5px;
    }
    .modal.workout_modal .btn_list li{
        font-size:11px;
        line-height: 15px;
        color: #121525;
        padding: 5px 10px;
        white-space: nowrap;
        cursor: pointer;
    }
    .workout_modal .slot_content{
        display: flex;
        flex-direction: column;
    }
    .workout_modal .slot_label{
        display: flex;
        align-items: center;
        padding: 15px 45px;
        background: #fff;
        margin: 0 -45px;
        position: sticky;
        top: 0;
        z-index: 2;
        border-bottom: 1px solid #fff;
        cursor: pointer;
    }
    .workout_modal .slot_label:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: -1;
    }
    .workout_modal .slot_label:after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #f5f5f5;
        z-index: 1;
    }
    .workout_modal .slot_label h2{
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #121525;
        z-index: 2;
    }
    .workout_modal .slot_label svg{
        z-index: 2;
        margin-right: 15px;
    }
    .workout_modal .slot_label i{
        margin-left: auto;
        font-size: 12px;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .slot_label.rotate i{
        transform: rotate(180deg);
    }
    .workout_modal .slot_label svg.morning{
        height: 16px;
        width: 31px;
    }
    .workout_modal .slot_label svg.morning path{
        fill: var(--var-calendar-morning-icon-color, #ff7553);
    }
    .workout_modal .slot_label svg.afternoon{
        height: 25px;
        width: 31px;
    }
    .workout_modal .slot_label svg.afternoon path{
        fill: var(--var-calendar-afternoon-icon-color, #f2a31d);
    }
    .workout_modal .slot_label svg.evening{
        height: 20px;
        width: 31px;
    }
    .workout_modal .slot_label svg.evening path{
        fill: var(--var-calendar-evening-icon-color, #548fe3);
    }
    :deep(.slot_label.morning) {
        color: var(--var-calendar-morning-text-color, #121525);
    }
    :deep(.slot_label.morning:after) {
        background-color: var(--var-calendar-morning-icon-color-light, #ff755325);
    }
    :deep(.slot_label.morning h2) {
        color: var(--var-calendar-morning-text-color, #121525);
    }
    /* :deep(.slot_label.morning svg path) {
        fill: var(--var-calendar-morning-icon-color, #ff7553);
    } */

    :deep(.slot_label.afternoon) {
        color: var(--var-calendar-afternoon-text-color, #121525);
    }
    :deep(.slot_label.afternoon:after) {
        background-color: var(--var-calendar-afternoon-icon-color-light, #f2a31d25);
    }
    :deep(.slot_label.afternoon h2) {
        color: var(--var-calendar-afternoon-text-color, #121525);
    }
    :deep(.slot_label.afternoon svg path) {
        fill: var(--var-calendar-afternoon-icon-color, #f2a31d);
    }

    :deep(.slot_label.evening) {
        color: var(--var-calendar-evening-text-color, #121525);
    }
    :deep(.slot_label.evening:after) {
        background-color: var(--var-calendar-evening-icon-color-light, #548fe325);
    }
    :deep(.slot_label.evening h2) {
        color: var(--var-calendar-evening-text-color, #121525);
    }
    :deep(.slot_label.evening svg path) {
        fill: var(--var-calendar-evening-icon-color, #548fe3);
    }
    .workout_modal .slot_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .slot_content .toggle_content.show{
        max-height: 100000px;
    }
    .workout_modal .slot_content .empty_text{
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        color: #999;
        text-align: center;
        padding: 25px 0;
    }
    .workout_modal h3.sub_heading2{
        display: flex;
        align-items: center;
        /* cursor: pointer; */
        font-size: 16px;
        font-weight: 500;
        padding: 30px 0 0;
        margin: 0;
    }
    .workout_modal h3.sub_heading2 i{
        color: #999;
        margin-left: auto;
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
        display: none;
    }
    .workout_modal h3.sub_heading2.rotate i{
        transform: rotate(-180deg);
    }
    .workout_modal .workout_card{
        display: flex;
        /* max-height: 0; */
        overflow: hidden;
        flex-direction: column;
        padding-bottom: 10px;
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid #e9e9e9;
    }
    .workout_modal .workout_card:last-of-type{
        border: 0;
        padding-bottom: 20px;
    }
    /* .workout_modal .workout_card.show{
        max-height: 1000px;
    } */
    .workout_modal .workout_card li{
        display: flex;
        flex-direction: column;
    }
    .workout_modal .workout_card li a{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: var(--var-calendar-event-cta-color, #2f7eed);
        margin: 0 0 0 auto;
        cursor: pointer;
    }
    .workout_modal .workout_card li .category_title{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        margin: 0 auto 7px 0;
    }
    .workout_modal .workout_card li .card_wrapper{
        border-radius: 6px;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 12px;
        cursor: pointer;
        padding: 20px 0 0 0;
    }
    .workout_modal .workout_card li .card_wrapper svg{
        flex: 0 0 18px;
        height: 18px;
    }
    .workout_modal .workout_card li .video_area{
        width: 25%;
        border-right: 1px solid #f5f5f5;
    }
    .workout_modal .workout_card li .video_area img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .workout_modal .workout_card li .info_wpr{
        border-bottom: 1px solid #e9e9e9;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding-bottom: 20px;
        gap: 0;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .workout_card li:last-child .info_wpr{
        border-bottom: 0;
    }
    .workout_modal .workout_card li .info_wpr h4{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        display: flex;
        /* align-items: center; */
        cursor: pointer;
        padding-right: 20px;
        gap: 7px;
        justify-content: space-between;
        position: relative;
    }
    .workout_modal .workout_card li .info_wpr h4 .lim_width{
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;
    }
    .workout_modal .workout_card li .info_wpr span.tag{
        background: #ecf4ff;
        padding: 1px 6px;
        border-radius: 8px;
        font-size: 9px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
    }
    .workout_modal .workout_card li .info_wpr h4 i{
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 10px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .workout_list li .info_wpr h4 i{
        margin-left: 15px;
        pointer-events: none;
    }
    .workout_modal .workout_card li .info_wpr h6{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        /* margin: 0 -5px 5px -5px; */
        gap: 5px;
    }
    .workout_modal .workout_card li .info_wpr h6 span{
        padding: 0 5px 0 0;
    }
    .workout_modal .workout_card li .info_wpr h6 span:not(:last-child){
        border-right: 1px solid #d9d9d9;
    }
    .workout_modal .workout_card li .info_wpr .blocks{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-bottom: 10px;
        flex: 0 0 100%;
    }
    .workout_modal .workout_card li .info_wpr .blocks .block_title{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
    }
    .workout_modal .workout_card li .info_wpr .blocks .block_title .block_type{
        font-size: 9px;
        line-height: 12px;
        color: var(--var-calendar-event-tag-text-color, #2f7eed);
        font-weight: 500;
        margin-left: 10px;
        background: var(--var-calendar-event-tag-color, #ecf4ff);
        padding: 3px 8px;
        border-radius: 10px;
    }
    .workout_modal .workout_card li .info_wpr .blocks .block_title h6{
        font-weight: 400;
        margin-top: 10px;
    }

    /* ---------- workout plan list for swiper---------------- */
    .workout_modal .workout_card li .info_wpr .plan_list{
        padding: 15px;
        border-radius: 10px;
        background: #eff5ff;
        position: relative;
        z-index: 1;
    }
    .workout_modal .workout_card li .info_wpr .blocks .swiper-slide .block_title{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .workout_modal .plan_list .block_type{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 400;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
    }
    .workout_modal .plan_list .item_list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px 0px;
    }
    .workout_modal :deep(.swiper-button-next), .workout_modal :deep(.swiper-button-prev){
        top: 0;
        width: 20px;
        height: 20px;
        margin: 0;
    }
    .workout_modal :deep(.swiper-button-next.swiper-button-disabled), .workout_modal :deep(.swiper-button-prev.swiper-button-disabled){
        opacity: 0;
    }
    .workout_modal :deep(.swiper-button-next){
        right: 0;
    }
    .workout_modal :deep(.swiper-button-next:after) {
        content: '\f30b';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-element-color);
    }

    .workout_modal :deep(.swiper-button-prev) {
        left: 0;
    }

    .workout_modal :deep(.swiper-button-prev:after) {
        content: '\f30a';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-element-color);
    }

    /* ----------- end --------------- */
    .workout_modal .workout_card li .info_wpr .block_item{
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }
    .workout_modal .workout_card li .info_wpr .block_item h5{
        font-size: 11px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
    }
    .workout_modal .workout_card li .info_wpr .block_item h6{
        font-size: 9px;
        line-height: 12px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .workout_modal .workout_card li .info_wpr .block_item h6 .v_tag {
        width: 14px;
        height: 14px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
        padding: 0;
    }
    .table_wpr{
        width: 406px;
        overflow-x: auto;
    }
    table.task_history{
        min-width: 350px;
        width: 100%;
        border-spacing: 1px;
        background: #eaeaea;
    }
    .task_history th, .task_history td {
        padding: 10px 0;
        border-radius: 3px;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
    }
    .task_history thead tr{
        border-bottom: 1px solid #e9e9e9;
    }
    .task_history td.exercise_title{
        padding: 7px 15px;
        background: #fbfbfb;
        font-size: 11px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
        text-align: left;
        cursor: pointer;
    }
    .task_history td.exercise_title .title{
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
    }
    .task_history td.exercise_title .title i{
        color: #5a5a5a;
    }
    .task_history td.exercise_title img{
        display: none;
    }
    .task_history td.exercise_title.show img{
        display: block;
    }
    .task_history td  {
        width: 10%;
        background: #fff;
        color: #777;
    }
    .workout_modal .workout_card li .info_wpr .todo_list{
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 10px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li{
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        color: #5a5a5a;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li label {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #d9d9d9;
        color: #777;
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-top: 2px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans{
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: flex-start;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_no_box{
        display: flex;
        gap: 7px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_no_box.disabled{
        opacity: 0.5;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_box{
        width: 60px;
        /* border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e; */
        border: 1px solid #e9e9e9;
        background: #fbfbfb;
        color: #777;
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        border-radius: 4px;
        padding: 5px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 7px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_box i{
        font-size: 10px;
        margin-top: 1px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .no_box{
        width: 60px;
        /* border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414; */
        border: 1px solid #e9e9e9;
        background: #fbfbfb;
        color: #777;
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        border-radius: 4px;
        padding: 5px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 7px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .no_box i{
        font-size: 10px;
        margin-top: 3px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .ans_text{
        min-width: 50px;
        background: #f5f5f5;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .workout_modal .workout_card li .info_wpr .details{
        display: flex;
        flex-wrap: wrap;
        max-height: 0;
        overflow: hidden;
        gap: 15px;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .workout_card li .info_wpr .details .feedback_btn{
        background: #f5f5f5;
        padding: 4px 10px;
        border-radius: 11px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        margin: 10px auto 0 auto;
    }
    .workout_modal .workout_card li .info_wpr .details .workout_reviews{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-height: 0;
        padding-bottom: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .workout_card li .info_wpr .details .workout_reviews.show{
        max-height: 500px;
        padding-bottom: 15px;
        margin-top: 10px;
    }
    .workout_reviews .finish_timer{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 0 15px;
    }
    .workout_reviews h3{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
    }
    .workout_reviews .finish_timer .total_timer{
        display: flex;
        gap: 7px;
    }
    .workout_reviews .finish_timer .total_timer label{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
    }
    .workout_reviews .finish_timer .total_timer label span{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .workout_reviews .reviews{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .workout_reviews .reviews ul{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
        width: 100%;
    }
    .workout_reviews .reviews ul li{
        flex: 1 1 auto;
        background: #f5f5f5;
        border-radius: 3px;
        padding: 10px;
        font-size: 9px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
    .workout_reviews .reviews ul li span{
        font-size: 15px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
    }
    .workout_reviews .notes{
        background: #f5f5f5;
        padding: 10px 15px 15px 15px;
        border-radius: 5px;
    }
    .workout_reviews .notes h4{
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .workout_reviews .notes p{
        font-size: 11px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .workout_modal .workout_card li .info_wpr .details .footer_action{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
    .cloud-icon{
        background: #f0f6ff;
        padding: 1px 6px;
        border-radius: 8px;
        font-size: 9px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
    }
    .cloud-icon.green{
        background: #effff2;
        color: #23993e;
    }
    .workout_modal .workout_card li .info_wpr .details p.already-submitted {
        font-size: 11px;
        line-height: 14px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: auto;
    }
    .workout_modal .workout_card li .info_wpr .details .redirect_btn{
        font-size: 11px;
        line-height: 14px;
        padding: 6px 12px;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        margin: 0 0 0 auto;
        text-decoration: none;
    }
    .workout_modal .workout_card li .info_wpr .details .details_info{
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        gap: 7px;
    }
    .workout_modal .workout_card li.show .info_wpr{
        gap: 15px;
    }
    .workout_modal .workout_card li.show .info_wpr h4 i{
        transform: rotate(-180deg);
    }
    .workout_modal .workout_card li.show .info_wpr .details{
        max-height: 10000px;
    }
    /* .booking_details{
        background: #fff;
        border-radius: 0 0 8px 8px;
        padding: 20px 20px 30px 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 25px;
    } */
    .booking_details .group_details{
        width: 100%;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 15px;
    }
    .booking_details .item_details{
        flex: 0 1 50%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .booking_details h5 {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .workout_modal .workout_card li .booking_details h6 {
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #777;
    }
    .booking_details p{
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #888;
    }
    .workout_modal .workout_card li .booking_details a.link{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #2f7eed;
        text-decoration: none;
        word-break: break-all;
        margin: 0;
        padding-left: 5px;
    }
    .additional_info{
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .location_details{
        max-width: 370px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        column-gap: 15px;
    }
    .location_fld{
        flex: 0 1 300px;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 20px;
        position: relative;
        padding: 0 40px 0 0;
    }
    .location_fld input{
        width: 100%;
        height: 35px;
        padding: 0 0 0 15px;
        font-size: 11px;
        color: #5a5a5a;
        font-weight: 400;
        background: transparent;
    }
    .location_fld .suffix{
        position: absolute;
        right: 0;
        top: 0;
        border-left: 1px solid #e9e9e9;
        width: 40px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .location_fld .suffix img{
        width: 16px;
        height: auto;
    }
    .booking_details label.checkbox_opt{
        flex: 0 0 65px;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        gap: 10px;
    }
    .booking_details label.checkbox_opt .check_box{
        width: 16px;
        height: 16px;
        border: 1px solid #999;
        background: #fff;
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }
    .booking_details label.checkbox_opt .check_box i{
        font-size: 10px;
        color: #121525;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .booking_details label.checkbox_opt .check_box.active i{
        transform: scale(1);
    }
    .booking_details label.checkbox_opt h5{
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
    }
    .booking_details .action_btns{
        padding: 10px 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }
    .workout_modal .workout_card .booking_details .action_btns a{
        font-size: 11px;
        line-height: 14px;
        padding: 6px 12px;
        border: 1px solid #e9e9e9;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        margin: 0;
    }
    .workout_modal .workout_card .booking_details .action_btns a i{
        font-size: 9px;
    }
    .booking_details .action_btns a.delete_btn{
        color: #eb1414;
    }
    /* .info_wpr .score_circle {
        position: absolute;
        right: 15px;
        top: 15px;
    } */
    .info_wpr .score_circle .inner_circle{
        background: #fff;
    }
    .info_wpr .score_circle .inner_circle .score {
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
    }

    .event_calendar{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .event_calendar :deep(.fullCalendar){
        width: 100%;
        margin: 15px 0 50px 0;
    }
    .event_calendar :deep(.fc-header-toolbar){
        justify-content: flex-start;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button){
        background: transparent;
        height: 27px;
        padding: 5px 10px;
        color: #121525;
        font-size: 11px;
        line-height: 1;
        border-radius: 3px;
        border: 1px solid #dbdbdb;
        outline: 0;
        box-shadow: none !important;
        text-transform: capitalize;
        margin-left: 3px;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-complete-button){
        margin-left: 10px;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-button-active){
        background: #dbdbdb;
    }
    /* .event_calendar :deep(.fc-header-toolbar .fc-button.fc-complete-button.active), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-incomplete-button.active){
        background: #dbdbdb;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-button-active){
        background: #dbdbdb;
    } */
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-prev-button), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-next-button){
        border-radius: 50%;
        padding: 0;
        width: 25px;
        height: 25px;
        margin-right: 7px;
        background: #dbdbdb;
        color: #121525;
        font-size: 13px;
        line-height: normal;
        border: 0;
    }
    .event_calendar :deep(.fc .fc-toolbar-title){
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        margin-left: 10px;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-toolbar-chunk:last-of-type){
        margin-left: auto;
    }
    .event_calendar :deep(.fc-dayGridMonth-view), .event_calendar :deep(.fc-dayGridWeek-view){
        overflow-x: auto;
    }
    .event_calendar :deep(table.fc-scrollgrid){
        border-radius: 10px;
        width: 100%;
    }
    .event_calendar :deep(.fc-dayGridMonth-view table.fc-scrollgrid){
        min-width: 850px;
    }
    .event_calendar :deep(.fc-dayGridWeek-view table.fc-scrollgrid){
        min-width: 1120px;
    }
    .event_calendar :deep(table.fc-col-header), .event_calendar :deep(.fc-daygrid-body), .event_calendar :deep(table.fc-scrollgrid-sync-table){
        width: 100% !important;
    }
    .event_calendar :deep(.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > th:last-child){
        border-radius: 0 10px 0 0;
        background: transparent;
    }
    .event_calendar :deep(.fc-scrollgrid-section-body > td){
        border-radius: 0 0 10px 10px;
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell){
        padding: 10px 5px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        background: #fff;
        position: relative;
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell:after){
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.1;
        background: var(--var-portal-tab-background-color);
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell:first-child), .event_calendar :deep(.fc-view .fc-col-header-cell:first-child:after){
        border-top-left-radius: 10px;
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell:last-child), .event_calendar :deep(.fc-view .fc-col-header-cell:last-child:after){
        border-top-right-radius: 10px;
    }
    .event_calendar :deep(.fc-view tbody tr:last-child td:first-child){
        border-bottom-left-radius: 10px;
    }
    .event_calendar :deep(.fc-view tbody tr:last-child td:last-child){
        border-bottom-right-radius: 10px;
    }
    .event_calendar :deep(.fc-theme-standard td.fc-daygrid-day){
        height: 120px;
    }
    .event_calendar :deep(.fc .fc-daygrid-day.fc-day-today){
        position: relative;
        background: #fff;
    }
    .event_calendar :deep(.fc .fc-daygrid-day.fc-day-today:before){
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.05;
        background: var(--var-calendar-event-color);
    }
    .event_calendar :deep(.fc .fc-daygrid-day.fc-day-future){
        background: #fff;
    }
    .event_calendar :deep(.fc .fc-day-other.fc-day-future){
        background: #fbfbfb;
    }
    .event_calendar :deep(.fc .fc-event){
        background: transparent;
        z-index: auto;
        border: 0;
        white-space: normal;
    }
    .event_calendar :deep(.fc-daygrid-day-events){
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .event_calendar :deep(.fc-event .fc-event-main){
        display: flex;
        padding: 2px;
        position: static;
        white-space: normal;
    }
    .event_calendar :deep(.fc-daygrid-day-events label), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label){
        font-size: 11px;
        line-height: 14px;
        background: var(--var-calendar-event-color);
        font-weight: 500;
        color: var(--var-calendar-text-color);
        padding: 4px 15px;
        border-radius: 11px;
        position: relative;
        cursor: pointer;
    }
    .event_calendar :deep(.fc-daygrid-day-events label.event), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label.event){
        background: #dae9ff;
    }
    .event_calendar :deep(.fc-daygrid-day-events label.workout), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label.workout){
        padding: 4px 40px 4px 10px;
        background: #f1f1f1;
    }
    .event_calendar :deep(.fc-day-today .fc-daygrid-day-events label.workout){
        background: #fff;
    }
    .event_calendar :deep(.fc-daygrid-day-events label span), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label span){
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background: #dbdbdb;
        color: #121525;
        padding: 3px 8px;
        font-size: 9px;
        line-height: 12px;
        border-radius: 0 12px 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .event_calendar :deep(.fc .fc-daygrid-more-link){
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        background: transparent;
        margin-top: 5px;
    }
    .event_calendar :deep(.fc-theme-standard .fc-popover){
        z-index: 9;
    }
    .event_calendar :deep(.fc .fc-more-popover .fc-popover-body){
        min-width: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 10px;
    }
    .event_calendar :deep(.fc-theme-standard .fc-popover-header){
        padding: 10px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        background: #fff;
        border-bottom: 1px solid #e9e9e9;
    }
    .event_calendar :deep(.fc-theme-standard .fc-popover-header .fc-icon-x:before){
        font-weight: 600;
    }

    .event_calendar :deep(.quick_info){
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
        background: #fff;
        width: auto;
        min-width: 180px;
        max-height: 175px;
        overflow-y: scroll;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 100%;
        bottom: auto;
        transform: translateX(-50%);
        display: none;
        margin-top: 5px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 0 10px 10px 10px;
        z-index: 10;
    }
    .event_calendar :deep(.fc-dayGridMonth-view .fc-scrollgrid-sync-table tr:last-child .quick_info){
        bottom: 100%;
        top: auto;
        margin-top: 0;
        margin-bottom: 5px;
    }
    .event_calendar :deep(.quick_info::-webkit-scrollbar){
        width: 3px;
    }
    .event_calendar :deep(.quick_info::-webkit-scrollbar-thumb){
        border-radius: 2px;
        background: #dbdbdb;
    }
    .event_calendar :deep(.fc-view tbody td.fc-day:first-child .quick_info){
        left: -20px;
        transform: none;
    }
    .event_calendar :deep(.fc-view tbody td.fc-day:last-child .quick_info){
        right: -20px;
        left: auto;
        transform: none;
    }
    .event_calendar :deep(.quick_info:after) {
        content: "";
        position: absolute;
        bottom: 100%;
        top: -6px;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(-135deg);
        z-index: 2;
        display: none;
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info){
        /* display: flex; */
        flex-direction: column;
        /* gap: 5px; */
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .event_calendar :deep(.fc-dayGridMonth-view .fc-scrollgrid-sync-table tr:last-child .fc-event:hover .quick_info){
        animation: smoothMoveUp 0.5s;
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info span){
        font-size: 10px;
        line-height: 14px;
        color: #121525;
        font-weight: 600;
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info i.success){
        color: #28a745;
        margin-left: 5px;
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info i.danger){
        color: #eb1414 ;
        margin-left: 5px;
    }
    .event_calendar :deep(.quick_info .event_info){
        display: flex;
        align-items: flex-start;
        gap: 6px;
        padding: 0 2px 12px 2px;
    }
    .event_calendar :deep(.quick_info .event_info span){
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        border-radius: 50%;
        font-size: 8px;
        line-height: 14px;
        color: #121525;
        font-weight: 500 !important;
        text-align: center;
        background: #e9e9e9;
    }
    .event_calendar :deep(.quick_info h5){
        flex: 1 1 auto;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        text-align: left;
    }
    .event_calendar :deep(.quick_info h6){
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .event_calendar :deep(.quick_info .tag){
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        background: #e9e9e9;
        padding: 3px 10px;
        border-radius: 10px;
        color: #121525;
        margin-top: 3px;
    }
    :deep(.event_calendar .quick_info .event_info.event-group-title) {
        text-transform: capitalize;
        align-items: center;
        margin-left: -10px;
        margin-right: -10px;
        border-top: 1px solid #fff;
        padding: 7px 10px;
        height: 35px;
    }
    :deep(.event_calendar .quick_info .event_info.event-group-title + .event_info){
        padding-top: 8px;
    }
    :deep(.event-group-title.morning) {
        background-color: var(--var-calendar-morning-icon-color-light, #ff755325);
        color: var(--var-calendar-morning-text-color, #121525);
    }
    :deep(.event-group-title.morning h5) {
        color: var(--var-calendar-morning-text-color, #121525);
    }

    :deep(.event-group-title svg.morning) {
        height: 15px !important;
        width: 16px !important;
    }
    :deep(.event-group-title svg.morning path) {
        fill: var(--var-calendar-morning-icon-color, #ff7553);
    }
    :deep(.event-group-title.afternoon) {
        background-color: var(--var-calendar-afternoon-icon-color-light, #f2a31d25);
        color: var(--var-calendar-afternoon-text-color, #121525);
    }
    :deep(.event-group-title.afternoon h5) {
        color: var(--var-calendar-afternoon-text-color, #121525);
    }

    :deep(.event-group-title svg.afternoon) {
        height: 15px !important;
        width: 16px !important;
    }
    :deep(.event-group-title svg.afternoon path) {
        fill: var(--var-calendar-afternoon-icon-color, #f2a31d);
    }

    :deep(.event-group-title.evening) {
        background-color: var(--var-calendar-evening-icon-color-light, #548fe325);
        color: var(--var-calendar-evening-text-color, #121525);
    }
    :deep(.event-group-title.evening h5) {
        color: var(--var-calendar-evening-text-color, #121525);
    }

    :deep(.event-group-title svg.evening) {
        height: 13px !important;
        width: 16px !important;
    }
    :deep(.event-group-title svg.evening path) {
        fill: var(--var-calendar-evening-icon-color, #548fe3);
    }

    :deep(.workout_modal .workout_card li .info_wpr span.tag) {
        background-color: var(--var-calendar-event-tag-color, #ecf4ff);
        color: var(--var-calendar-event-tag-text-color, #2f7eed);
    }

    :deep(.workout_modal .workout_card li .info_wpr .details .redirect_btn) {
        color: var(--var-calendar-event-cta-color, #2f7eed);
    }

    /* week view */
    .event_calendar :deep(.fc-dayGridWeek-view .fc-daygrid-day), .event_calendar :deep(.fc-dayGridWeek-view .fc-col-header-cell){
        width: 160px;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .fc-daygrid-day-events){
        align-items: flex-start;
        padding: 0 0 10px 0;
        /* gap: 10px; */
    }
    .event_calendar :deep(.fc-dayGridWeek-view .fc-event), .event_calendar :deep(.fc-dayGridDay-view .fc-event){
        margin: 0;
        padding: 0;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .fc-daygrid-event-harness), .event_calendar :deep(.fc-dayGridDay-view .fc-daygrid-event-harness) {
        width: 100%;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .activity_list){
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        width: 100%;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .activity_list .list_item){
        padding: 0 3px 12px 27px;
        position: relative;
        cursor: pointer;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .activity_list .list_item svg) {
        width: 14px;
        height: 14px;
        position: absolute;
        left: 5px;
        top: 2px;
        cursor: pointer;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .activity_list .list_item strong){
        padding-right: 5px;
    }
    :deep(.event_calendar .fc-dayGridWeek-view .activity_list .list_item.event-group-title){
        text-transform: capitalize;
        align-items: center;
        padding: 5px;
        height: 35px;
        display: flex;
        gap: 6px;
    }
    :deep(.event_calendar .fc-dayGridWeek-view .activity_list .list_item.event-group-title + .list_item){
        margin-top: 8px;
    }

    :deep(.event_calendar .fc-dayGridWeek-view .activity_list .list_item.event-group-title svg),
    :deep(.event_calendar .fc-dayGridDay-view .activity_list .list_item.event-group-title svg) {
        position: relative;
        top: auto;
        left: auto;
    }

    /* day view */
    .event_calendar :deep(.fc-dayGridDay-view){
        max-width: 350px;
    }
    .event_calendar :deep(.fc-dayGridDay-view .fc-daygrid-day-events){
        align-items: flex-start;
        padding: 0 0 15px 0;
        /* gap: 10px; */
    }
    .event_calendar :deep(.fc-dayGridDay-view .fc-daygrid-day-events){
        max-width: 500px;
        margin: 0 auto;
    }
    .event_calendar :deep(.fc-dayGridDay-view .fc-event .fc-event-main){
        flex-direction: column;
    }
    .event_calendar :deep(.fc-dayGridDay-view .activity_list){
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        width: 100%;
    }
    .event_calendar :deep(.fc-dayGridDay-view .activity_list .list_item){
        padding: 0 10px 20px 42px;
        position: relative;
        cursor: pointer;
    }
    .event_calendar :deep(.fc-dayGridDay-view .activity_list .list_item svg){
        width: 20px;
        height: 20px;
        position: absolute;
        left: 12px;
        top: 0;
        cursor: pointer;
    }
    .event_calendar :deep(.fc-dayGridDay-view .activity_list .list_item strong){
        padding-right: 8px;
    }
    :deep(.event_calendar .fc-dayGridDay-view .activity_list .list_item.event-group-title) {
        text-transform: capitalize;
        border-top: 1px solid #fff;
        align-items: center;
        padding: 0 10px;
        height: 40px;
        display: flex;
        gap: 10px;
    }
    :deep(.event_calendar .fc-dayGridDay-view .activity_list .list_item.event-group-title + .list_item){
        margin-top: 15px;
    }
    :deep(.fc-dayGridDay-view .event-group-title svg.morning) {
        height: 17px !important;
        width: 22px !important;
    }
    :deep(.fc-dayGridDay-view .event-group-title svg.afternoon) {
        height: 21px !important;
        width: 22px !important;
    }
    :deep(.fc-dayGridDay-view .event-group-title svg.evening) {
        height: 17px !important;
        width: 22px !important;
    }
    @keyframes smoothMove {
        0% {
            top: 30%;
            opacity: 0;
        }

        10% {
            top: 30%;
            opacity: 0.5;
        }

        100% {
            top: 100%;
            opacity: 1;
        }
    }
    @keyframes smoothMoveUp{
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }
    .workout_modal .view_plan{
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100vh;
        background: #fff;
        z-index: 3;
        transition: all 0.5s ease-in-out;
        overflow-y: scroll;
    }
    .workout_modal .view_plan::-webkit-scrollbar{
        display: none;
    }
    .workout_modal .view_plan.show{
        top: 0;
    }
    .workout_modal .view_plan .close_btn{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        padding: 0 15px;
        background: #2f7fed;
        border-radius: 0 0 6px 6px;
        font-size: 10px;
        color: #fff;
        cursor: pointer;
        width: auto;
        height: 12px;
    }
    .workout_modal .view_plan .header{
        border-bottom: 1px solid #e9e9e9;
        background: #fff;
        z-index: 11;
        position: sticky;
        top: 0;
        padding: 30px 0 15px 0;
    }
    .workout_modal .view_plan h2{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
    }
    .workout_modal .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .workout_modal .cell:after,
    .workout_modal .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .workout_modal .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .workout_modal .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .workout_modal .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
    .workout_modal .cell .content_area::-webkit-scrollbar{
        display: none;
    }

    .result_wpr.new{
        overflow: visible;
    }
    .portal_forms{
        /* margin-bottom: 50px; */
    }
    .inner_pages .actions .filter_btn {
        padding: 0;
        display: flex;
        gap: 30px;
    }

    .inner_pages .actions .filter_btn .btn_item {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    ul.days {
        border-top: 1px dashed #E4E7EC;
        display: flex;
        justify-content: center;
        padding: 20px 0;
        gap: 8px;
    }

    .workout_modal .workout_card li .info_wpr .todo_list li.w-40 {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        align-items: center;
        flex-direction: column-reverse;
    }

    .inner_pages .actions .filter_btn .btn_item.active {
        color: #121525;
        font-weight: 500;
    }
    .result_wpr.new .actions > ul{
        gap: 0;
        height: auto;
    }
    .result_wpr.new .actions > ul li.search_area{
        background: #fff;
        height: 35px;
        margin-left: auto;
    }
    .result_wpr.new .actions > ul li.search_area input[type=text]{
        font-size: 11px !important;
    }
    .result_wpr.new .actions > ul li.search_area.active input{
        width: 250px;
    }

    .section_item h3 .title{
        display: flex;
        align-items: center;
    }
    .section_item h3 .tooltip_icon{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #e9e9e9;
        margin-left: 15px;
        font-size: 10px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .action-button {
        position: relative;
    }

    .action-button .action {
        width: 30px;
        height: 30px;
        font-size: 12px;
        color: #666;
        border-radius: 15px;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 3;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .action-button:hover .action {
        opacity: 1;
    }

    .client_card{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto 30px auto;
        overflow: hidden;
    }
    .dash_tabs{
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .dashboard_content{
        max-width: 1200px;
    }
    .extra_info{
        padding: 20px 20px 25px 20px;
    }
    .chat_btn.ripple{
        animation: ripple 0.7s linear infinite;
    }
    .chat_header{
        background: var(--var-chat-btn-color);
    }
    .chat_header .coach .coach_box .coach_name{
        color: var(--var-chat-btn-color);
    }
    :deep(.chat .chat_user .coach_box .coach_name){
        color: var(--var-chat-btn-color);
    }
    :deep(.chat_box .post){
        background: var(--var-chat-btn-color);
    }

    .board_status {
        width: 100%;
    }

    .board_status > ul{
        display: flex;
        flex-wrap: wrap;
        padding: 15px 0 20px 0;
        margin: 0 -15px;
    }
    .board_status > ul > li{
        width: 33.333%;
        padding: 15px;
        display: flex;
        flex-direction: column;
    }
    .board_status .handle-cat-drag{
        font-size: 15px;
        color: #9f9e9e;
        cursor: move;
    }

    .categories_list{
        padding: 5px 15px;
        border-radius: 15px;
        background: #e9e9e9;
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        position: relative;
        min-width: 100px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .categories_list i{
        margin-left: auto;
        padding-left: 10px;
    }
    .categories_list .dropdown_wpr{
        min-width: 170px;
    }
    .categories_list .dropdown_wpr::before{
        display: none;
    }
    .client_card nav .mobile_bar svg *{
        fill: var(--var-header-text-color);
    }
    @keyframes ripple {
        0% {
            box-shadow: 0 0 0 0 var(--var-chat-bg-color90), 0 0 0 10px var(--var-chat-bg-color90);
        }
        100% {
            box-shadow: 0 0 0 10px var(--var-chat-bg-color90), 0 0 0 20px var(--var-chat-bg-color0);
        }
    }
    @media(max-width: 1350px){
        .board_status > ul{
            margin: 0 -15px;
        }
    }
    @media(max-width: 1250px){
        .sliderWpr .client_productList li {
            flex: 0 0 33.333%;
        }
         .event_calendar :deep(.fc-theme-standard td.fc-daygrid-day){
            height: 100px;
        }
    }
    @media(max-width: 1199px){
        .inner_pages .actions .filter_btn{
            gap: 20px;
        }
        .inner_pages .actions .filter_btn .btn_item {
            font-size: 13px;
        }
        .board_status > ul > li{
            width: 50%;
        }
    }
    @media(max-width: 1024px){
        .inner_pages .actions .filter_btn{
            width: 100%;
            justify-content: flex-start;
        }
        .result_wpr.new .actions > ul{
            row-gap: 15px;
        }
        .result_wpr.new .actions > ul li.contacts-tabs{
            padding: 7px 0;
        }
        .result_wpr.new .actions > ul li:last-child .dropdown_wpr {
            right: auto;
            left: 1px;
        }
        /* .result_wpr.new .actions > ul li.search_area{
            order: 1;
        } */
    }
    @media(max-width: 768px){
        .section_header{
            margin-bottom: 20px;
        }
        .section_header > ul{
            gap: 0;
        }
        .modal .modal_header{
            padding: 0 30px;
        }
        .modal.workout_modal .modal_body{
            padding: 0 25px 20px 25px;
        }
        .modal .modal_footer {
            padding: 15px 25px;
        }
        .workout_modal .slot_label{
            padding: 15px 25px;
            margin: 0 -25px;
        }
        .workout_modal .workout_card li .card_wrapper{
            gap: 10px;
        }
        .dashboard_content .right_side{
            margin: 0;
        }
        .sliderWpr{
            max-width: 600px;
            margin: 0 auto;
        }
        .sliderWpr .client_productList li {
            flex: 0 0 50%;
        }
        /* .workout_list{
            padding: 20px 30px;
            margin: 0 -30px;
        } */
        .workout_modal .workout_card li .info_wpr h4{
            flex-direction: column;
            align-items: flex-start;
        }
        .workout_modal .workout_card li .info_wpr .details .details_info{
            padding: 0 15px 15px 15px;
        }
        /* .workout_modal .workout_card li .card_wrapper{
            gap: 0;
        } */
        .workout_modal .workout_card li .video_area{
            width: 100%;
            border-right: 0;
            border-bottom: 1px solid #f5f5f5;
        }
        .workout_modal .workout_card li .video_area iframe {
            border-radius: 6px 6px 0 0;
        }
        /* .workout_modal .workout_card li .info_wpr{
            width: 100%;
            padding: 15px;
        } */

        .result_wpr.new .actions > ul li.search_area{
            border-radius: 17px !important;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 200px;
        }
        .dashboard_content h3 .data-v-tooltip:not([position]):after{
            left: 115%;
        }
        .table_wpr{
            width: 268px;
        }
        .workout_modal .view_plan .cell{
            transform: scale(0.7) translateX(-9%) translateY(-20%);
        }

        .workout_modal .workout_card li .info_wpr h4 .lim_width{
            flex-direction: row;
            gap: 0;
            align-items: center;
        }
        .board_status > ul > li{
            width: 100%;
        }
    }
    @media(max-width: 650px){
        .event_calendar :deep(.fc-header-toolbar){
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
        }
        .event_calendar :deep(.fc-header-toolbar .fc-toolbar-chunk:last-of-type){
            width: 100%;
            display: flex;
            justify-content: center;
        }
        h3.section_title{
            width: 100%;
            margin-bottom: 20px;
        }
        .result_wpr.new .actions > ul,
        .result_wpr.new .actions > ul li.search_area,
        .result_wpr.new .actions > ul li.search_area input{
            width: 100%;
        }
        .categories_list{
            margin-left: auto;
        }
        .client_productList .product_card .product_cont .primary_btn{
            font-size: 12px;
        }
    }
    @media(max-width: 599px){
        .section_header > ul{
            width: 100%;
            margin-top: 15px;
        }
        .section_header .tutorial_btn, .section_header > ul > li:last-child{
            margin-left: auto;
        }
        .section_header .tutorial_btn .dropdown_wpr{
            left: auto;
            right: 1px;
        }
    }
    @media(max-width: 499px){
        .dashboard_content .section_item{
            padding: 20px 0;
        }
        .logo_img{
            max-height: 25px;
        }
        .client_card .card_header{
            padding: 15px;
        }
        .client_card .card_header .dashboard-menu-text h4{
            font-size: 9px;
            line-height:11px !important;
            padding: 4px 10px;
        }
        .client_card .card_header .user_box img{
            width: 20px;
            height:20px;
        }
        .client_card .card_header .user_box h5{
            font-size: 11px;
            line-height: 14px;
        }
        .sliderWpr{
            max-width: 280px;
            margin: 0 auto;
        }
        .sliderWpr .client_productList li {
            flex: 0 0 100%;
        }
        .event_calendar :deep(.fc-theme-standard td.fc-daygrid-day){
            height: 80px;
        }
        .event_calendar :deep(.fc .fc-toolbar-title){
            font-size: 15px;
            line-height: 20px;
            margin-left: 5px;
        }
        .event_calendar :deep(.fc-header-toolbar .fc-button), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-complete-button), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-incomplete-button){
            height: 25px;
            padding: 5px;
            font-size: 11px;
        }
        .event_calendar :deep(.fc-header-toolbar .fc-button.fc-prev-button), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-next-button){
            width: 20px;
            height: 20px;
            margin-right: 7px;
            font-size: 11px;
        }
        .chat_btn.ripple{
            animation: none;
        }
        .chat_btn.ripple:before{
            display: block;
        }
    }
</style>
